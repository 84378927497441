/* eslint-disable no-unused-vars */
import React, {
  FocusEventHandler, KeyboardEventHandler, useContext, useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { UserDataContext } from '../../../App';
import ConsoleImproved from '../../classes/ConsoleImproved';
import { COLORS } from '../../colors/COLORS';
import { MeetingData } from '../../types/types';
import { titleSemibold } from '../../typography';
import ReactTooltip from '../tooltip/ReactTooltip';

interface TitleProps {
  isEditor?: boolean,
}

const TitleSemibold = styled.span<TitleProps>`
  ${titleSemibold};
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  width: 100%;

  ${({ isEditor }) => isEditor && css`
    cursor: pointer;
  `}
    
`;

const Input = styled.input`
  ${titleSemibold}
  width: 10px;
  flex: 1;
  box-sizing: border - box;
  outline-color: ${COLORS.gray4};
`;

interface Props {
  meetingData: MeetingData,
  meetingCreator: string,
  // eslint-disable-next-line no-unused-vars
  updateTitle: (title: string) => void,
}

const MeetingTitleOrg = ({ meetingData, meetingCreator, updateTitle }: Props) => {
  const userData = useContext(UserDataContext);

  const textRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
  const [displayText, setDisplayText] = useState<string>('');
  const [isTextOverflow, setTextOverflow] = useState<boolean>(false);
  const [isEditing, setEditing] = useState<boolean>(false);

  const isEditorForMeeting = meetingCreator === userData.data.email;

  useEffect(() => {
    setDisplayText(meetingData.data.title);
  }, [meetingData.data.title]);

  useEffect(() => {
    setTextOverflow(textRef.current.scrollWidth > textRef.current.offsetWidth);
  }, [textRef, meetingData.data.title]);

  useEffect(() => {
    if (!isEditing) return;
    inputRef.current.focus();
  }, [isEditing]);

  const enterEditMode = () => {
    setEditing(true);
  };

  const exitEditMode = (finalText: string, update: boolean = true) => {
    const trimmedText = finalText.trim();
    if (trimmedText.length === 0 && update) return;
    setEditing(false);
    if (trimmedText === meetingData.data.title) return;
    if (!update) return;
    setDisplayText(trimmedText);
    updateTitle(trimmedText);
  };

  const onClickTitle = () => {
    if (!isEditorForMeeting) return;
    ConsoleImproved.log('onClickTitle');
    enterEditMode();
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    exitEditMode(event.target.value);
  };

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Escape') {
      exitEditMode('', false);
    } else if (event.key === 'Enter') {
      const inputElement: any = event.target;
      exitEditMode(inputElement.value);
    }
  };

  if (isEditing) {
    return (
      <Input
        type="text"
        ref={inputRef}
        defaultValue={displayText}
        onBlur={onInputBlur}
        onKeyDown={onInputKeyDown}
      />
    );
  }

  if (isTextOverflow) {
    return (
      <ReactTooltip tip={meetingData.data.title} place="bottom">
        <TitleSemibold
          isEditor={isEditorForMeeting}
          onClick={onClickTitle}
          ref={textRef}
        >
          {displayText}
        </TitleSemibold>
      </ReactTooltip>
    );
  }

  return (
    <TitleSemibold
      isEditor={isEditorForMeeting}
      onClick={onClickTitle}
      ref={textRef}
    >
      {displayText}
    </TitleSemibold>
  );
};

export default MeetingTitleOrg;
