/* eslint-disable no-undef */
/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';
import Font from '../../../typography/Font';
import UpgradeNowButton from '../../../../pages/all-pages/Billing/TrialEndedOfferExtensionModal/UpgradeNowButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface WorkspaceBillingOrgProps {
  workspace: WorkspaceData
  onClickSubscribe: () => void
}

const WorkspaceBillingOrg = ({ workspace, onClickSubscribe }: WorkspaceBillingOrgProps) => {
  if (!workspace.name) {
    return null;
  }

  return (
    <Container>
      <Font font="uiTextMedium" color="gray10">
        Billing status:
        {' '}
        {workspace.billing.subscription.status}
      </Font>
      <Font font="uiTextMedium" color="gray10">
        Is workspace on premium:
        {' '}
        {workspace.isOnPremium().toString()}
      </Font>
      {
        !workspace.isOnPremium() && (
        <UpgradeNowButton onClick={onClickSubscribe} />
        )
      }
    </Container>
  );
};

export default WorkspaceBillingOrg;
