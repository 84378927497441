import { Block } from '@meetshepherd/martian/build/src/notion';
import React, { Dispatch, SetStateAction } from 'react';
import {
  blue6, cyan6, green6, purple6, red6,
} from '../colors/COLORS';
import {
  DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBOARD_VIEW, HEADER_ONBOARDING_VIEW, HEADER_SIGNIN_VIEW,
} from '../components/header/utils/constants';
import {
  InviteSource, MEETING_PAGE, SLACK, UNKNOWN,
} from '../../utils/analytics/enums';
import {
  EMAIL, GOOGLE_CALENDAR, GAPI_RESPONSE_TYPE, TASK_TAB,
  NOTION, TRELLO, ASANA, GOOGLE_DOCS, JIRA, ALL_PAGES, RESOLVED,
  REJECTED, PENDING, NOT_SYNCED, SYNCED, NOTIFICATION, MEETING_SECTION, CHROME_EXTENSION,
  MOBILE, TABLET, DESKTOP, CHROME, OPERA, EDGE, SAFARI, FIREFOX, IE,
} from '../../utils/enums';
import BillingUserData from '../../external/Stripe/BillingUserData';
import { IBillingUserData, StripeSubscription } from '../../external/Stripe/StripeTypes';

export interface AuthState {
  userState: LoginState;
  userId: string;
  firstName: string,
  lastName: string,
  email: string,
  photoUrl: string
}

export type TimeTrackingEvents = 'appLoad' | 'attendeesLoad' | 'notesLoad' | 'meetingDataLoad'
export type SharePlatforms = typeof EMAIL | typeof GOOGLE_CALENDAR | IntegrationType
export type LoginState = 'loggedIn' | 'loggedOut' | 'unresolved';
/** In this context `private` is displayed to users as `My Notes` */
export type NoteType = 'agenda' | 'shared' | 'private' | 'secret' | 'scratchpad';
/**
 * Which section you are in for a specific meeting within current meeting view
 *
 * i.e. `my notes`, `shared notes`, `tasks`
 */
export type MeetingSection = NoteType | 'task';
export type ConferenceType = 'googleMeet' | 'zoom' | 'undefined';

export interface MeetingData extends CoreMeetingData {
  resolvedState: ResolvedState;
  meetingId: string;
  permissions: Permissions;
  userRole: UserPermissions;
  attendees: {
    attendees: AttendeeV2[];
    resolvedState: ResolvedState;
  };
}

// How the data is stored in the database
export interface DatabaseMeetingData extends CoreMeetingData {
  permissions: DatabasePermissions;
}

export interface Permissions {
  users: SimpleUserData[];
  userGroups: string[];
  tags: string[];
  linkPermissions: LinkPermissions;
}

/** Meeting Data Fields that are common between database model and model used in the code */
interface CoreMeetingData {
  version: MeetingVersion;
  data: {
    agenda: any[];
    attachments: any[];
    attendees: any[];
    description: string;
    title: string;
    postMeetingTasks: any[];
    preMeetingTasks: any[];
    status?: string
  };
  tags: {
    tags: string[];
    meetingSeries: {
      name: string;
      id: string;
    };
  };
  date: {
    created: SDateT;
    /**
     * The date format differs slightly between the database and GAPI.
     *
     * GAPI returns:
     * ```
     * {
     *  date: '2020-10-01',
     *  dateTime: '2020-10-01T00:00:00-07:00',
     *  timeZone: 'America/Los_Angeles'
     * }
     * ```
     * where either `date` or `dateTime` is populated. `date` is populated only
     * if the event is an all-day event. In our database, we store the date as
     *
     * ```
     * {
     *  date: '2020-10-01T00:00:00-07:00',
     *  timestamp: 1601510400000
     * }
     * ```
     *
     * This way we can query efficiently.
     *
     * To set the `date` property in our database, we use either of GAPI `date` or `dateTime`,
     * depending on whether the event is an all-day event, and therefore which one is populated,
     * and convert it to an ISO string. This is done in `getDateObjectFromGoogleDate`
     */
    start: SDateT;
    /** Check `date.start` comment */
    end: SDateT;
    lastUpdated: SDateT;
  };
  googleData: {
    ids: {
      /** In Google Calendar Event Resource it's simply called `id` */
      eventId: string;
      recurringEventId: string;
      dataEventId: string; // Previously googleEventId
      calendarId: string;
      meetId: string;
      /** The first part of `htmlLink`is the same as the first 52 characters of
       *  `data-event-id` in meeting cards in Google Calendar (GC).
       * In GC, before you open a meeting, you can just see it in your schedule,
       * we have not access to the event id, so we need another way to identify
       * the meeting. We have access to the `data-event-id` and it seems like
       * the first 52 characters is the same as the `htmlLink` in the event resource.
       */
      htmlLink: string;
    },
    attendees: string[];
    content: {
      summary: string;
      organizer: {
        /**
         * `calendarId` of the event.
         *
         * The creator can be different from the calendarId, if the creator selects
         * a different calendar than his primary calendar.
         */
        email: string;
      },
      creator: {
        email: string,
      }
    }
    conferenceData: ConferenceData,
  };
}

export interface ConferenceData {
  type: ConferenceType,
  link: string,
}

export interface Tag extends DatabaseTag {
  tagId: string;
}

export interface DatabaseTag {
  name: string;
  data: {
    description: string,
    color: string,
    state: 'active' | 'inactive',
  },
  date: {
    created: SDateT,
    updated: SDateT,
  },
  permissions: {
    creator: {
      userId: string,
      email: string
    },
    admins: string[],
    members: string[],
  }
}
export interface DatabasePermissions {
  users: DatabaseUsers;
  userGroups: string[];
  tags: string[];
  linkPermissions: LinkPermissions;
}

/**
 * Main user object containing data from Firestore
 */
export interface User extends CoreUser {
  resolvedState: ResolvedState;
  userId: string,
  friendListV2: FriendListV2;
  /**
  * A lot of components uses the PublicUserDataV2 type
  * and sometimes we want to pass in the auth user itself.
  * For convenience, we have this property here.
   */
  publicUserData: PublicUserDataV2,
  billing: BillingUserData,
  /**
   * The user can have selected a workspace. This is the workspace that is currently selected.
   */
  workspaces: UserWorkspaceData,
}

export type UserWorkspaceData = {
  /**
   * The user should always have selected a workspace.
   */
  selectedWorkspaceId: string,
};

export interface DatabaseUser extends CoreUser {
  friendList2: DatabaseFriendListV2;
  billing: IBillingUserData;
}

export interface CoreUser {
  data: {
    name: string;
    email: string;
    firstName: string,
    lastName: string,
    photoUrl: string
    newFeaturesViewed: string[],
    hasDismissedDesktopNotification: boolean,
    hasOnboarded: boolean,
    onboarding: OnboardingData,
    receivedWelcomeEmail: boolean
  },
  meta: {
    admin: boolean;
    analytics: {
      hasUsedScratchpad: boolean;
    },
    /** Private Id, only known to the user. Used by Scratchpad to create a private note */
    privateId: string,
  },
  settings: UserSettings,
  googleTokens: GoogleOfflineTokens
  date: {
    created: SDateT,
    updated: SDateT,
  },
  integrations: IntegrationData,
  invitees: UserInviteesData
  friendList: PublicUserData[],
  permissions: {
    google: {
      calendars: string[]
    },
    tags: string[]
  }
}

export interface GoogleOfflineTokens {
  accessToken: string,
  refreshToken: string,
  scope: string,
  tokenType: string,
  idToken: string,
  expiryDate: number // as google provides expiryDate in timestamp format
}

export interface UserInviteesData {
  [inviteId: string]: InviteeData;
}

export interface UserSettings {
  fanOfCoding: boolean,
  fanOfPPT: boolean,
  receiveNewsletter: boolean,
  defaultUserTab: MeetingSection,
  receiveTaskEmail: boolean,
  isDesktopNotificationEnabled: boolean,
  desktopNotification: DesktopNotification,
  clockFormat: AMPMor24h,
  /** Domains where the Chrome Extension should be disabled */
  disabledDomains: string[],
}

export interface DesktopNotification {
  endpoint: string,
  expirationTime: string | null,
  keys: {
    p256dh: string,
    auth: string
  }
}

export interface IntegrationData {
  slack: SlackData[],
  notion: NotionData[],
  trello: TrelloData,
  jira: JiraData,
}

export interface OnboardingData {
  personalShepherdUsage: string,
  jobType: string,
  companySize: string,
  productivityTool: string,
  remotePolicy: string
}

export interface SlackData {
  version: SlackIntegrationVersion
  userAccessToken: string,
  botAccessToken: string,
  userId: string,
  defaultChannels: SlackChannel[],
  notifications: SlackNotificationsSettings,
  date: {
    created: SDateT,
    updated: SDateT,
  },
}

export interface SlackNotificationsSettings {
  meetingStartsSoon: boolean,
  mentionedInNotes: boolean,
  taskOverdue: boolean,
  taskCreated: boolean,
  taskUpdated: boolean,
  taskDeleted: boolean,
}

export interface SlackNotificationData {
  title: string,
  recipientEmail: string,
  /** Slack blocks in markdown format to be sent */
  blocks: any,
}

export interface NotionData {
  accessToken: string,
  botId: string,
  workspaceName: string,
  workspaceIcon: string,
  workspaceId: string,
  topLevelPageId: string,
  topLevelPageName: string,
  parentPageId: string,
}

export interface NotionPageData {
  pageId: string,
  parentPageId: string,
  title: string,
  workspaceToken: string
}

export interface NotionCreatePageData {
  workspaceToken: string,
  parentPageId: string,
  pageTitle: string,
  block: Block[]
}

export interface TrelloData {
  // TODO Asish: version should be of type TrelloIntegrationVersion, similar to MeetingVersion
  // and then add a description to each value so we understand the meaning of each value
  // TODO2: Add commas between the fields
  version: number
  settings: {
    isTrelloEnabled: boolean
    isAutoSyncEnabled: boolean
    isAllowOtherToSyncEnabled: boolean,
  }
  accessToken: string
  webhookId: string
  workspaceId: string,
  workspaceName: string,
  board: TrelloBoardData
}

export interface TrelloBoardData {
  boardId: string,
  boardName: string,
  boardURL: string,
  todoList: TrelloListData,
  inProgressList: TrelloListData,
  completedList: TrelloListData,
}

export interface TrelloListData {
  listId: string
  listName: string,
}

export interface JiraData { }

export interface DatabaseUsers {
  [key: string]: SimpleUserData;
}

export interface SimpleUserData extends SimpleUserDataCore {
  access: boolean;
  role: MeetingRole;
}

export interface SimpleUserDataCore {
  userId: string;
  name: string;
  email: string;
  date: {
    added: SDateT;
    created: SDateT;
    lastViewed: SDateT;
  };
}

export interface PublicUserData {
  name: string;
  email: string;
  userId: string;
  photoUrl: string;
  integrations: IntegrationData
}

export interface FriendListV2 {
  resolvedState: ResolvedState;
  users: PublicUserDataV2[];
}

export interface DatabaseFriendListV2 {
  [userId: string]: DatabasePublicUserV2;
}

export interface DatabasePublicUserV2 {
  email: string,
}

export interface PublicUserDataV2 {
  // When updating this object, also need to update the same object in
  // functions types
  resolvedState: ResolvedState,
  userId: string,
  isShepherdUser: boolean,
  data: {
    name: string;
    email: string;
    firstName: string,
    lastName: string,
    photoUrl: string
  }
  external: {
    email: {
      receiveTaskEmail: boolean,
    },
    slack: {
      hasEnabledSlack: boolean,
      notifications: SlackNotificationsSettings,
    },
    trello: {
      isTrelloEnabled: boolean
      isAutoSyncEnabled: boolean
      isAllowOtherToSyncEnabled: boolean,
      version: number
    }
  }
}

export interface UserFeedback {
  created: string
  email: string,
  feedback: string,
  name: string,
  userId: string,
}

/**
 * Shepherd Date
 * @date {string}
 * @timestamp {number} Typically used to sort or filter dates
 */
export interface SDateT {
  date: string;
  timestamp: number;
}

export type LinkPermissions = 'private' | 'public_view' | 'public_edit';

export interface UserPermissions {
  canEdit: boolean;
  canEditTitle: boolean;
  canEditMeetingSeries: boolean;
  canEditLinkSettings: boolean;
  canAddUser: boolean;
  canAddAdmin: boolean;
  canAddModerator: boolean;
  canAddEditor: boolean;
  canAddCommenter: boolean;
  canAddViewer: boolean;
  canRemoveUser: boolean;
  canAddGroup: boolean;
  canDeleteMeeting: boolean;
  canComment: boolean;
  canView: boolean;
  isLastAdmin: boolean;
  role: MeetingRole;
}

export type MeetingRole =
  | 'noAccess'
  | 'public_view'
  | 'public_edit'
  | 'viewer'
  | 'commenter'
  | 'editor'
  | 'moderator'
  | 'admin';

/**
 * `3` - Text editor v2 - Prosemirror + Realtime database
 *
 * `4` - Agenda is deprecated
 *
 * `5` - Text editor v3 - Prosemirror + yjs + Realtime database
 *
 * NB: When updating version, update this function as well:
 * `generateMeetingVersionTooltipText`
 */
export type MeetingVersion = 1 | 2 | 3 | 4 | 5;

// Setter functions
export type SetLoadingType = React.Dispatch<React.SetStateAction<boolean>>;
export const DummySetLoading = () => { };
export type SetMeetingDataType = React.Dispatch<React.SetStateAction<MeetingData>>
export type SetTaskDataType = React.Dispatch<React.SetStateAction<TaskItem>>

// Intercom
export type IntercomState = boolean;

/**
 * Also includes info if the meeting have notes or not
 */
export interface GapiMeetingDataExtended extends GapiMeetingData {
  /** Indicates if the meeting have notes or not.
   *
   * `RESOLVED` => The meeting have notes
   *
   * `REJECTED` => The meeting have NOT notes
   *
   * `PENDING` => We have not checked yet if this meeting have notes or not
   * */
  hasNotes: ResolvedState;
}

export interface GapiMeetingData extends DatabaseGapiMeetingData {
  resolvedState: ResolvedState,
}

export type GapiMeetingDate = {
  dateTime: string,
  timeZone: string,
  /** Only used for full day events. I.e. `2022-11-09`.
   * If one day full day event, then end.date: `2022-11-10` */
  date: string,
}

export type DatabaseGapiMeetingData = {
  updated: string,
  summary: string,
  description: string,
  status: string,
  start: GapiMeetingDate,
  end: GapiMeetingDate,
  sequence: number,
  reminders: {
    useDefault: boolean
  },
  organizer: {
    /** calendarId */
    email: string,
    self: boolean
  },
  kind: string,
  /** `id` = `eventId` */
  id: string,
  /**
   * Should not be longer than 52 characters.
   * Can be used to match with data-event-id in Google Calendar DOM
   */
  htmlLink: string,
  iCalUID: string,
  eventType: string,
  recurringEventId: string,
  recurrence: string[],
  etag: string,
  creator: {
    email: string,
    self: boolean
  },
  created: string,
  conferenceData: {
    conferenceId: string,
    conferenceSolution: {
      iconUri: string,
      key: {
        type: string
      },
      name: string
    },
    entryPoints: EntryPoint[],
    signature: string,
  },
  attendees: GoogleAttendee[],
};

/**
 * https://developers.google.com/calendar/api/v3/reference/calendarList#resource
 */
export type GapiCalendarData = {
  kind: 'calendar#calendarListEntry',
  // 'etag': etag, // Don't know what this is
  etag: string, // Don't know what this is
  id: string,
  summary: string,
  description: string,
  location: string,
  timeZone: string,
  summaryOverride: string,
  colorId: string,
  backgroundColor: string,
  foregroundColor: string,
  hidden: boolean,
  selected: boolean,
  /**
   * The effective access role that the authenticated user has on the calendar.
   *  Read-only. Possible values are:
   *
   * - `freeBusyReader` - Provides read access to free/busy information.
   * - `reader` - Provides read access to the calendar.
   * Private events will appear to users with reader access, but event details will be hidden.
   * - `writer` - Provides read and write access to the calendar.
   * Private events will appear to users with writer access, and event details will be visible.
   * - `owner` - Provides ownership of the calendar. This role has all of the permissions
   * of the writer role with the additional ability to see and manipulate ACLs.
   */
  accessRole: string,
  defaultReminders: [
    {
      method: string,
      minutes: number,
    }
  ],
  notificationSettings: {
    notifications: [
      {
        type: string,
        method: string
      }
    ]
  },
  /**
   * Whether the calendar is the primary calendar of the authenticated user.
   * Read-only. Optional. The default is False.
   */
  primary: boolean,
  deleted: boolean,
  conferenceProperties: {
    allowedConferenceSolutionTypes: [
      string
    ]
  }
}

export type GapiResponseStatus = typeof GAPI_RESPONSE_TYPE.ACCEPTED
  | typeof GAPI_RESPONSE_TYPE.DECLINED | typeof GAPI_RESPONSE_TYPE.TENTATIVE
  | typeof GAPI_RESPONSE_TYPE.NEEDS_ACTION | typeof GAPI_RESPONSE_TYPE.UNKNOWN;

export type GoogleAttendee = {
  email: string,
  responseStatus: GapiResponseStatus,
  organizer: boolean,
  comment: string,
}

export interface AttendeeProfile extends GoogleAttendee {
  userId: string,
  name: string,
  email: string,
  photoUrl: string
}

export type EntryPoint = {
  entryPointType: string,
  label: string,
  uri: string
}

export type PrivateNoteHtml = {
  noteId: string,
  contents: string,
};

export type TaskTabKeys = keyof typeof TASK_TAB;
export type TaskStatus = typeof TASK_TAB[TaskTabKeys];
/** Since we don't show any overdue tab, we exclude it from the type */
export type TaskTab = Exclude<TaskStatus, typeof TASK_TAB.OVERDUE>;

export type DueDateType = 'date' | 'preMeetingTask' | 'noDueDate';

export type BasicUser = {
  userId: string,
  name: string,
  email: string,
  photoUrl: string,
}

export type DatabaseAssignee = {
  userId: string,
  name: string,
  email: string,
  photoUrl: string,
}

export interface AttendeeV2 extends PublicUserDataV2 {
  // TODO: Actually set the responseStatus enum of what it can be
  responseStatus: string
}

export interface PrivateNoteAttendeeV2 extends PublicUserDataV2 {
  isNotified: boolean
}

export interface AssigneeV2 extends PublicUserDataV2 { }
export interface ReporterV2 extends PublicUserDataV2 { }

export type Reporter = BasicUser;

export type TaskItemVersion = 1 | 2;

export type TaskPermissions = MeetingRole;

export interface TaskItem extends CoreTaskItem {
  taskId: string,
  assignee: AssigneeV2,
  reporter: ReporterV2,
}

export interface DatabaseTaskItem extends CoreTaskItem {
  assignee: DatabaseAssignee,
  reporter: DatabaseAssignee,
}

export type CoreTaskItem = {
  version: TaskItemVersion;
  date: {
    created: SDateT,
    updated: SDateT,
    dueDate: {
      type: DueDateType,
      date: SDateT,
      meeting: {
        meetingId: string;
        startDate: SDateT,
        name: string,
      },
    },
  },
  data: {
    status: TaskStatus;
    completed: boolean;
    assignee: BasicUser;
    reporter: Reporter;
    title: string;
    description: string;
    isPrivate: boolean;
    isViewed: boolean;
  },
  integrations: {
    trello: { // TODO: Should be own type
      trelloTaskId: string,
      isTrelloSyncEnabled: boolean
    },
    slack: {
      isOverdueNotificationSent: boolean,
    }
  },
  meeting: TaskMeetingData,
  order: {
    privateIndex: number, // Index in consolidated task lits
    privatePrevTaskId: string, // Linked list, prev item
    privateNextTaskId: string, // Linked list, next item
    meetingIndex: number, // Index in meeting task list
    meetingPrevTaskId: string, // Linked list, prev item
    meetingNextTaskId: string, // Linked list, next item
  },
  // Always set to 'editor'
  permissions: TaskPermissions;
}

export interface TaskItems {
  resolvedState: ResolvedState,
  tasks: TaskItem[],
}

export interface TaskMeetingData {
  meetingId: string;
  startDate: SDateT,
  tags: string[],
  name: string,
}

export type IntegrationType = typeof SLACK | typeof NOTION | typeof TRELLO |
  typeof ASANA | typeof GOOGLE_DOCS | typeof JIRA;

export type DefaultSlackChannel = {
  value: string,
};

export type SlackChannel = {
  id: string,
  name: string,
}

export type AccessToken = {
  token: string,
}
export type TaskOrderField = 'privateIndex' | 'meetingIndex';

export type TasksPage = 'meeting' | 'allTasks';

export type RelativeTiming = 'before' | 'after' | 'during' | undefined;

export type MeetingAnalyticsData = {
  hasUsedShepherd?: boolean,
  hasAgenda?: boolean,
  hasSharedNotes?: boolean,
  hasPrivateNotes?: boolean,
  users?: MeetingUserAnalyticsData[]
}

export type MeetingUserAnalyticsData = {
  userId?: string,
}

export interface TemplateData extends DatabaseTemplateData {
  templateId: string
}

// version 1 is for templates created for the firepad text editor (meetingDataVersion 2)
// version 2 is for templates created for the prosemirror text editor (meetingDataVersion 3)
export type TemplateVersion = 1 | 2;

export type TemplateShareStatus = 'private' | 'public';

export type DatabaseTemplateData = {
  version: TemplateVersion
  data: {
    title: string,
    description: string
  },
  template: string,
  date: {
    created: SDateT,
    updated: SDateT
  },
  meeting: {
    meetingId: string,
    startDate: SDateT,
    title: string
  },
  creator: {
    userId: string,
    name: string,
    email: string
  },
  usage: {
    numberOfTimesUsed: number
  },
  share: {
    status: TemplateShareStatus
  }
};

export type WindowMessage = {
  data: any,
  id: string,
  type: string,
  sequence: number,
  ack: boolean,
};

export type CloseSidebarWindowMessage = {
  type: 'TOGGLE_SIDEBAR',
  showing: boolean
}

export type PreviousMeetingWindowMessageTypes = 'TOGGLE_PREVIOUS_MEETING_WINDOW' | 'SWITCH_PREVIOUS_MEETING_WINDOW';

export type PreviousMeetingWindowMessage = {
  type: PreviousMeetingWindowMessageTypes,
  meetingId: string,
  previousMeetingId: string
}

export type CEButtonEvent = {
  field: string,
}

// Need to be a string of a key of the ALL_PAGES object
type ALL_PAGES_KEYS = keyof typeof ALL_PAGES;
/** Decides which of the top tab you should be in: `All Notes`, `All Tasks`, `Current Note`,
 * or `Scratchpad` */
export type Page = typeof ALL_PAGES[ALL_PAGES_KEYS];

export type MeetingNotesSection = 'customPeriod' | 'all' | 'thisWeek' | 'recent' | 'thisMonth';

export type TaskNotificationType = 'delete' | 'assign' | 'update';
export type TaskUpdateField = 'dueDate' | 'title' | 'status' | 'description';
export type ToastNotificationType = 'success' | 'info' | 'danger' | 'warning';

export type UseShepherdFor = 'Bring structure to meetings'
  | 'Take collaborative notes'
  | 'Keep track of tasks'
  | 'Quickly share meeting summary'
  | 'All of the above';

export type Shortcut = 'navigateLeft' | 'navigateRight' | 'openCreateTask';

/**
 * Indicated if we are waiting for data to be fetched `pending`
 * or we have successfully resolved the data `resolved`
 * or we failed fetching the data `rejected`
 */
export type ResolvedState = typeof RESOLVED | typeof REJECTED | typeof PENDING;
export type ResolvedStateExtended = ResolvedState | 'null';

export type SlackMessageContent = {
  /*
  there is no type for slackBlocks as the parsing of markdown
  to slack blocks returns a array of objects and each object is
  of diffrent type based on the text type hence 'any' for blocks
*/
  channel: string,
  blocks: any,
  mrkdwn: boolean,
}

// export type FontType = 'Open Sans' | 'Arial' | 'Lato' | 'Montserrat' | 'Raleway' | 'Roboto'

export type TextEditor = 'firepad' | 'prosemirror';

export type ShareModalTab = 'sendNotes' | 'integrations';

export type IntegrationsTabView = 'Overview' | 'SlackNotifications' | 'SlackProcessing' | 'NotionProcessing' | 'TrelloProcessing' | 'SlackSelectDefaultChannel' | 'SlackSuccessfullyIntegrated' | '';

export type NewFeatures = {
  id: string,
  features: NewFeature[],
}

export type NewFeature = {
  id: number,
  title: string,
  /** Need to be a react component */
  Explanation: any,
  media: string,
}

export type SendGridEmailRecipient = {
  email: string,
};

export interface PublicUserPrivateNoteData extends PublicUserDataV2 {
  hasAccess: boolean,
}

export interface PrivateNoteData extends PrivateNote {
  chatId: string,
}

export type PrivateNote = {
  title: string,
  chatPathInRealtimeDb: string,
  members: PrivateNoteAttendeeV2[],
  userIds: string[],
  meetingId: string,
  created: SDateT,
  updated: SDateT,
  creator: PublicUserDataV2,
}
export type ShortcutInfo = {
  name: string,
  commands: string[]
}

export type Quote = {
  quote: string,
  author: string,
}

// ANALYTICS

export type LoginOrSignup = 'login' | 'signup';
export type OnboardingType = 'organic' | 'invited';

// OPERATING SYSTEM

export type OperatingSystem = 'Mac' | 'Windows' | 'Linux';

interface NotesHtmlHookArgs {
  meetingId: string,
  userId: string,
}

interface NotesHtmlReturn {
  agendaHtml: string,
  privateHtml: string,
  sharedHtml: string,
}

// eslint-disable-next-line no-unused-vars
export type NotesHtmlHook = (args: NotesHtmlHookArgs) => NotesHtmlReturn;
export interface OperatingSystemState {
  operatingSystem: OperatingSystem,
  setOperatingSystem: Dispatch<SetStateAction<OperatingSystem>>,
}

export type DetectedOperatingSystem = OperatingSystem | 'Pending';

export interface DetectedOperatingSystemState {
  detectedOperatingSystem: DetectedOperatingSystem,
  setDetectedOperatingSystem: Dispatch<SetStateAction<DetectedOperatingSystem>>,
}

export type SlackUser = {
  iconUrl: string,
  name: string,
}

export type SlackIntegrationVersion = 1 | 2;

// eslint-disable-next-line no-unused-vars
export type IntercomTrackEvent = (event: string, metaData?: object | undefined) => void;

export type GoogleMeetingIds = {
  eventId: string,
  calendarId: string,
  resolvedState: ResolvedState,
}

/**
 * `meetingId` used in Firestore Database
 */
export type ShepherdMeetingId = {
  meetingId: string,
  resolvedState: ResolvedState,
}

export type WelcomeScreenColor = (typeof purple6)
  | (typeof green6)
  | (typeof cyan6)
  | (typeof red6)
  | (typeof blue6);

export type InviteEmailInput = {
  index: number,
  displayValue: string,
}
export type InviteData = {
  inviteId: string
  email: string,
  isSignedUp: boolean,
  inviteSource: InviteSource
  invitedBy: InviterData[]
}

export type InviterData = {
  userId: string,
  email: string,
  name: string,
  date: {
    inviteSent: SDateT
  }
}

export type InviteeData = {
  email: string,
  isSignedUp: boolean
  date: {
    inviteSent: SDateT,
  }
}
export interface ResolvedStateItem<T> {
  item: T,
  resolvedState: ResolvedState
}
export interface GoogleCalendarEventCreationData {
  summary: string,
  description: string,
  start: {
    dateTime: string,
    timeZone: string,
  },
  end: {
    dateTime: string,
    timeZone: string,
  },
}

export interface EmailSendingStatus {
  email: string;
  resolvedState: ResolvedState;
}

export type TopHeaderState = typeof DEFAULT_HEADER_MEETING_VIEW
  | typeof HEADER_DASHBOARD_VIEW | typeof HEADER_ONBOARDING_VIEW
  | typeof HEADER_SIGNIN_VIEW;

export type ProseMirrorView = {
  page: string,
  editorView: any,
};

/**
 * Interface for logging time details of a event
 *
 * when: "before" | "after" | "during" relative timing of the meeting in words
 *
 * timeDiff: min difference relative to meet start/end time,
 * -ve for when: 'before'
 * +ve for when: 'after'
 * 0 for when: 'during'
 *
 * timestamp: timestamp of the time when event was logged
 */
export interface TimestampLog {
  when: RelativeTiming,
  timeDiff: number,
  timestamp: string
}

export type MeetingsByDate = { [date: string]: GapiMeetingData[] };

export interface NotificationData extends NotificationDatabase {
  db: NotificationDatabaseStatus
}

/** Indicates if the notification is stored and synched in the database or not */
export type NotificationDatabaseStatus = {
  state: typeof NOT_SYNCED | typeof SYNCED,
  /** Last time the FE fetched the data from the DB */
  lastUpdated: SDateT,
  documentId: string,
}

/** Data stored in the database */
export type NotificationDatabase = {
  version: NotificationVersion,
  type: NotificationType,
  recipient: BasicUser,
  reporter: BasicUser,
  read: {
    isRead: boolean,
    date: SDateT,
  },
  dismissed: {
    isDismissed: boolean,
    date: SDateT,
  },
  created: SDateT,
  updated: SDateT,
  source: NotificationSource,
  trigger: {
    priority: string,
    date: SDateT,
  },
  content: NotificationContent,
  actions: NotificationActions,
  history: NotificationHistoryEvent[] //
}

export type NotificationContent = {
  title: string,
  description1: string,
  description2: string,
  description3: string,
}

export type NotificationActions = {
  primary: NotificationAction,
  secondary: NotificationAction,
  tertiary: NotificationAction,
}

/** If you want to attach a custom action to the notification */
export type NotificationAction = {
  type: string,
  action: string,
  field: string,
  description1: string,
  description2: string,
}

export type NotificationHistoryEvent = {
  type: {
    type: string,
    identifier1: string,
    identifier2: string,
  },
  date: SDateT,
  user: BasicUser,
  data: {
    title: string,
    description1: string,
  }
}

type NotificationVersion = 1;

type INVITE = 'invite';

export type NotificationType = typeof NOTIFICATION | INVITE;

type WORKSPACE_TYPE = 'workspace';

export type NotificationSourceType = typeof MEETING_PAGE | typeof MEETING_SECTION | WORKSPACE_TYPE

/**
 * This type contains information about the source which triggered this notification
 *
 * For type === 'meeting'
 * @field identifier1: meetingId
 * @field identifier2: meetingTitle
 * @field identifier3: NoteType
 *
 * For type === 'task'
 * @field identifier1: taskId
 * @field identifier2: taskTitle
 * @field identifier3: TaskStatus
 */
export type NotificationSource = {
  type: NotificationSourceType
  identifier1: string,
  identifier2: string,
  identifier3: string,
}
export type TemplateType = 'shepherd' | 'user';

export type Device = typeof CHROME_EXTENSION | typeof MOBILE | typeof TABLET | typeof DESKTOP
export type Browser = typeof CHROME | typeof OPERA | typeof EDGE | typeof SAFARI
  | typeof FIREFOX | typeof IE | typeof UNKNOWN

const SCROLL_BOTTOM = 'scrollBottom';
const SCROLL_TOP = 'scrollTop';
const SCROLL_UPDATE = 'scrollUpdate';
export type ScrollState = typeof SCROLL_BOTTOM | typeof SCROLL_TOP | typeof SCROLL_UPDATE;

export type ScrollBehavior = 'smooth' | 'auto';

export type AMPMor24h = 'AMPM' | '24h';

export type MeetingsObject = { [calendarIdKey: string]: MeetingData[] };

/**
 * `attendeeEmailKey` should be the email of the attendee,
 * so we can easily query meetings where the user is an attendee
 *
 * i.e.
 * `.where('googleData.content.attendees.`${userData.data.email}`', '!=', false)`
 */
export type AttendeesObject = { [attendeeEmailKey: string]: GoogleAttendee };

export interface NewTagPayload {
  userId: string,
  userEmail: string,
  newTagName: string,
}

export interface AddTagToMeetingPayload {
  userId: string,
  userEmail: string,
  tagId: string,
  meetingId: string,
}

export interface RelevantMeetingSearchQuery {
  userEmail: string,
  userId: string,
  textQuery: string,
}

export type RelevantMeetingsData = {
  futureMeetings: MeetingData[],
  previousMeetings: MeetingData[],
  resolveState: ResolvedState,
}

/**
 * Should be exactly the same as what is in the database
 */
export type WorkspaceDb = {
  data: {
    name: string,
    description: string,
    color: string,
    picture: string,
    /** Creator email */
    createdBy: string,
  },
  permissions: {
    /**
     * I.e. ['meetshepherd.com', 'placewise.com']
     * Then all users with emails ending with meetshepherd.com or placewise.com
     * will be allowed to join the workspace
     */
    allowedDomains: string[],
    members: string[],
    editors: string[],
    managers: string[],
    admins: string[],
    /**
     * People that have been invited to the workspace but have not yet joined
     */
    invites: string[],
  },
  date: {
    created: SDateT,
    lastUpdated: SDateT,
  },
  privateId: string,
  billing: {
    subscription: StripeSubscription,
   }
}

export type WorkspacePermission = 'member' | 'editor' | 'manager' | 'admin' | 'invited';
/**
 * Is `WorkspacePermission` with `null` added as a possible value.
 *  In case you want to remove someone from the workspace
 */
export type WorkspacePermissionExpanded = WorkspacePermission | null;

export type Subscription = {
};

export type Invite = {
  type: 'workspace' | 'meeting',
  invitedEmail: string,
  identifiers: {
    workspaceId: string,
    workspaceName: string,
    workspaceAdmin: string,
    meetingId: string,
  }
}

export type TeamOrPrivate = 'team' | 'personal';
