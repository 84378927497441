/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import WorkspaceOnboardingTemplate from './WorkspaceOnboardingTemplate';
import CreateNewWorkspacePage from '../CreateNewWorkspace/CreateNewWorkspacePage';
import InviteTeammatesToWorkspacePage from '../InviteTeammates/InviteTeammatesToWorkspacePage';
import { TeamOrPrivate } from '../../../shared/types/types';

type WorkspaceScreen = 'HowToUseShepherd' | 'CreateWorkspace' | 'InviteTeam';

interface Props {
  // eslint-disable-next-line no-unused-vars
  navigateNextOrBack: (nextOrBack: 'next' | 'back') => void;
}

const WorkspaceOnboardingPages = ({
  navigateNextOrBack,
}: Props) => {
  const [screen, setScreen] = useState<WorkspaceScreen>('HowToUseShepherd');
  const [asTeamOrPersonal, setAsTeamOrPersonal] = useState<TeamOrPrivate>('team');

  const handleUseShepherd = (asTeamOrPrivate: TeamOrPrivate) => {
    console.log('WorkspaceOnboardingPage: handleClickContinue');
    setAsTeamOrPersonal(asTeamOrPrivate);
    setScreen('CreateWorkspace');
  };

  if (screen === 'HowToUseShepherd') {
    return (
      <WorkspaceOnboardingTemplate onClickContinue={handleUseShepherd} />
    );
  }

  if (screen === 'CreateWorkspace') {
    return (
      <CreateNewWorkspacePage asTeamOrPersonal={asTeamOrPersonal} onClickContinue={() => { setScreen('InviteTeam'); }} />
    );
  }

  return (
    <InviteTeammatesToWorkspacePage onClickContinue={() => { navigateNextOrBack('next'); }} />
  );
};

export default WorkspaceOnboardingPages;
