/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../shared/colors/COLORS';
import {
  GapiMeetingData, ScrollState, Page, ScrollBehavior,
} from '../../shared/types/types';
import { ALL_PAGES } from '../../utils/enums';
import { groupMeetingsByDate } from '../../utils/meetings/meetingsUtils';
import PageContainer from '../all-pages/PageContainer';
import AllNotesHeaderMolecule from './AllNotesHeaderMolecule';
import NotesListOrganism from './notes-list/NotesListOrganism';
import SearchbarNotes from './SearchbarNotes';
import { CSS_CONSTANTS } from '../../shared/CSS/CSS_Constants';

const Container = styled.div`
  height: 100%; 
  width: 100%;
  padding: 15px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; // so that the GoToTodayButtonMolecule can be positioned absolute
  & > * {
    width: 100%;
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 16px;
  max-width: ${CSS_CONSTANTS.MAX_WIDTH_CONTENT};
`;

interface Props {
  page: Page;
  notes: GapiMeetingData[];
  isLoading: boolean;
  isFetchMoreMeetingsBtnEnable: boolean;
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string, startDateTime: string) => void,
  // eslint-disable-next-line no-unused-vars
  onScroll: (status: ScrollState) => void,
  // eslint-disable-next-line no-unused-vars
  onClickToday: (behavior: ScrollBehavior) => void,
  fetchMeetingsAgain: () => void;
  onClickFetchMoreMeetings: () => void;
  searchText: string,
  // eslint-disable-next-line no-unused-vars
  onSearchTextChange: (text: string) => void;
}

const AllNotesTemplate = ({
  page, notes, isLoading, isFetchMoreMeetingsBtnEnable, onAction, onScroll,
  onClickToday, fetchMeetingsAgain, onClickFetchMoreMeetings,
  searchText, onSearchTextChange,
}: Props) => {
  const groupedNotes = groupMeetingsByDate(notes);

  if (page !== ALL_PAGES.ALL_NOTES) return null;

  return (
    <PageContainer id="AllNotesTemplate">
      <Container>
        <AllNotesHeaderMolecule
          isLoading={isLoading}
          fetchMeetingsAgain={fetchMeetingsAgain}
          onClickToday={onClickToday}
        />
        <SearchContainer>
          <SearchbarNotes text={searchText} onTextChange={onSearchTextChange} />
        </SearchContainer>
        {/* <NotesFilterBoardOrganism /> */}
        <NotesListOrganism
          groupedNotes={groupedNotes}
          isLoading={isLoading}
          onAction={onAction}
          onScroll={onScroll}
          onClickFetchMoreMeetings={onClickFetchMoreMeetings}
          isFetchMoreMeetingsBtnEnable={isFetchMoreMeetingsBtnEnable}
        />
      </Container>
    </PageContainer>
  );
};

export default AllNotesTemplate;
