import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import Header from '../../../shared/components/header';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../../shared/components/header/utils/constants';
import InvitePageMainContent from './InvitePageMainContent';
import WorkspaceAPI from '../../../database/Workspaces/WorkspaceAPI';
import { UserDataContext } from '../../../App';
import InviteClass from '../../../shared/classes/Invite/InviteClass';
import useNotificationStore from '../../../zustand/useNotificationStore';
import UserWorkspacesAPI from '../../../database/User/UserWorkspacesAPI';
import { toastSuccess } from '../../../utils/notifications';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';
import Logger from '../../../utils/analytics/Logger/Logger';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const InvitePage = () => {
  const handleSetPage = () => {};
  const userData = useContext(UserDataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [invites, setInvites] = useState<InviteClass[]>([]);
  const { notifications } = useNotificationStore();
  const navigate = useNavigate();

  const getInvites = async () => {
    try {
      const result = await WorkspaceAPI.getInvitesByEmail(userData.userId);
      setInvites(result);
    } catch (err) {
      // Notification.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Logger.Workspaces.logPageInvite(userData.userId);
  }, []);

  useEffect(() => {
    if (!userData.isResolved()) return;
    getInvites();
  }, [userData.email]);

  useEffect(() => {
    // If someone invites you, you will receive a new notification
    // Since we only fetch invites by a CF, we are not listening to the DB
    // but we know that if you get a new notification, you might have a new invite
    getInvites();
  }, [notifications]);

  const handleAccept = async (workspaceId: string, workspaceName: string) => {
    console.log('accepting invite');
    await WorkspaceAPI.acceptInvite(workspaceId, userData.userId);
    await UserWorkspacesAPI.setMyWorkspaceId(userData.userId, workspaceId);
    await getInvites();
    // TODO: Logging
    toastSuccess('Invite Accepted', `Current active workspace changed to '${workspaceName}'`, 10000);
    navigate(ROUTES.allNotes);
  };

  const handleDecline = async (workspaceId: string, workspaceName: string) => {
    console.log('declining invite');
    await WorkspaceAPI.declineInvite(workspaceId, userData.userId);
    await getInvites();
    // TODO: Logging
    toastSuccess('Invite Declined', `You have successfully declined the invite to ${workspaceName}.`);
  };

  const handleActionWorkspace = (workspaceId: string, workspaceName: string, acceptOrDecline: 'accept' | 'decline') => {
    if (acceptOrDecline === 'accept') {
      handleAccept(workspaceId, workspaceName);
    } else {
      handleDecline(workspaceId, workspaceName);
    }
  };

  return (
    <Container>
      <Header setPage={handleSetPage} view={DEFAULT_HEADER_MEETING_VIEW} />
      {/* <Navbar page={page} setPage={setPage} meetingData={meetingData} /> */}
      <InvitePageMainContent
        invites={invites}
        isLoading={loading}
        onAction={handleActionWorkspace}
      />
    </Container>
  );
};

export default InvitePage;
