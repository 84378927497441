/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { UserDataContext } from '../../../../App';
import { ModalLineLocal } from '../../../../pages/meeting/modal/ShareNotesModal';
import FireworkConfetti from '../../../confetti/FireworkConfetti';
import Modal from '../../Modal';
import ModalBodyWrapper from '../../Modal/ModalBodyWrapper';
import Font from '../../../typography/Font';
import WorkspaceModalWorkspaceNameAndIconMol from './WorkspaceModalWorkspaceNameAndIconMol';
import ButtonTabAtom from '../../button-tab/ButtonTabAtom';
import WorkspaceMember from '../../../../database/Workspaces/WorkspaceMember';
import { WorkspacePermissionExpanded } from '../../../types/types';
import WorkspaceMembersOrg from './WorkspaceMembersOrg';
import WorkspaceBillingOrg from './WorkspaceBillingOrg';

export const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 24px;

  button:last-child {
    margin-left: 8px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  > *:not(:last-child) {
    margin-right: 8px;
  }
  margin-bottom: 24px;
`;

const BodyContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
`;

interface HorizontalSpacingProps {
  isActive: boolean
  height: number
}

const HorizontalSpacing = styled.div<HorizontalSpacingProps>`
  ${({ isActive, height }) => (isActive && css`
    height: ${height}px;
    width: 100%;
  `)}
`;

type WorkspaceModalTab = 'members' | 'plan_and_billing' | 'integrations';

interface Props {
  isOpen: boolean,
  setMemberPermission: (email: string, newPermission: WorkspacePermissionExpanded) => void,
  members: WorkspaceMember[],
  setClosed: () => void,
  activateConfetti: boolean,
  onClickSubscribe: () => void
}

const WorkspaceSettingsModal = ({
  isOpen,
  setMemberPermission,
  members,
  setClosed,
  activateConfetti,
  onClickSubscribe,
}: Props) => {
  const userData = useContext(UserDataContext);
  const [activeTab, setActiveTab] = useState<WorkspaceModalTab>('members');
  const [isPermissionsDropdownOpen, setIsPermissionsDropdownOpen] = useState(false);

  return (
    <Modal title="Workspace Settings" isOpen={isOpen} setModalClosed={setClosed}>
      <ModalBodyWrapper>
        <WorkspaceModalWorkspaceNameAndIconMol workspaceName={userData.workspace.name} />
        <TabContainer>
          <ButtonTabAtom isActive={activeTab === 'members'} onClick={() => setActiveTab('members')}>Members</ButtonTabAtom>
          <ButtonTabAtom isActive={activeTab === 'plan_and_billing'} onClick={() => setActiveTab('plan_and_billing')}>Plan & Billing</ButtonTabAtom>
          <ButtonTabAtom isActive={activeTab === 'integrations'} onClick={() => setActiveTab('integrations')}>Integrations</ButtonTabAtom>
        </TabContainer>
        <BodyContainer>
          {activeTab === 'members' && (
            <WorkspaceMembersOrg members={members} workspace={userData.workspace} setMemberPermission={setMemberPermission} setIsPermissionsOpen={setIsPermissionsDropdownOpen} />
          )}
          {activeTab === 'plan_and_billing' && (
            <WorkspaceBillingOrg workspace={userData.workspace} onClickSubscribe={onClickSubscribe} />
          )}
          {activeTab === 'integrations' && (
            <Font font="uiTextMedium" color="gray10">Integrations</Font>
          )}
          <HorizontalSpacing isActive={isPermissionsDropdownOpen} height={50} />
        </BodyContainer>
      </ModalBodyWrapper>
      <ModalLineLocal />
      <ModalFooterWrapper>
        <Font font="uiTextMedium" color="gray1">Button 1</Font>
        <Font font="uiTextMedium" color="gray1">Button 2</Font>
      </ModalFooterWrapper>
      <FireworkConfetti shouldTrigger={activateConfetti} />
    </Modal>
  );
};

export default WorkspaceSettingsModal;
