/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom-v5-compat';

import { useIntercom } from 'react-use-intercom';
import { useStripe } from '@stripe/react-stripe-js';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import {
  GapiMeetingData, ScrollState, Page, ScrollBehavior, MeetingsObject,
} from '../../shared/types/types';
import {
  PENDING, RESOLVED, ALL_PAGES,
} from '../../utils/enums';
import useMeetingsAndUsersStore from '../../zustand/useAllMeetingsStore';
import { getOrMakeMeetingByEventAndCalendarId } from '../googleCalendar';
import { AuthContext, UserDataContext } from '../../App';
import { logAllNotesClickMeeting, logPageEvent } from '../../utils/analytics/eventLogger';
import { SidebarStateContext } from '../../ListenToSidebarMessageContainer';
import { ALL_NOTES_PAGE } from '../../utils/analytics/enums';
import { shouldWeLog } from '../../utils/analytics/functions';
import { mapMeetingDataToGapiMeeting } from '../../database/utils/gapiMeetingDataUtils';
import AlgoliaAPI from '../../database/Algolia/AlgoliaAPI';
import GAPI from '../../external/GoogleAPI/GAPI';
import FirestoreMeetings from '../../database/Meetings/FirestoreMeetings';
import { GapiUtils } from '../../utils/google/GoogleCalendarUtils';
import Logger from '../../utils/analytics/Logger/Logger';
import AllNotesTemplate from './AllNotesTemplate';
import BillingUtils from '../../external/Stripe/BillingUtils';
import { toastDanger, toastInfo } from '../../utils/notifications';
import UpgradeNowModal from '../all-pages/Billing/TrialEndedModal/UpgradeNow/UpgradeNowModalOrg';
import StripeAPI from '../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../external/Stripe/StripeConstants';
import Mixpanel from '../../utils/analytics/Mixpanel';

interface IsPayWallOpen {
  isOpen: boolean;
  startDateTime: string,
}

interface Props {
  page: Page;
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
}

const AllNotesPageCtrl = ({ page, setPage }: Props) => {
  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const navigate = useNavigate();
  const {
    meetings, resolveState,
    fetchMeetings, fetchMeetings7DaysFromStartDay,
    fetchMeetingsAgain,
  } = useMeetingsAndUsersStore();
  const [startDayMin, setStartDayMin] = useState<number>(-6);
  const [startDayMax, setStartDayMax] = useState<number>(0);
  const [isScrolledToToday, setIsScrolledToToday] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResultMeetings, setSearchResultMeetings] = useState<GapiMeetingData[]>([]);
  const [meetingsToShow, setMeetingsToShow] = useState<GapiMeetingData[]>([]);
  const [isLoadingGoToMeeting, setIsLoadingGoToMeeting] = useState<boolean>(false);
  const [meetingsFirestore, setMeetingsFirestore] = useState<MeetingsObject>({});
  const [lastTimeSearchedMeetings, setLastTimeSearchedMeetings] = useState<number>(0);
  const [isPayWallOpen, setIsPayWallOpen] = useState<IsPayWallOpen>({ isOpen: false, startDateTime: '' });
  const isSidebarOpen = useContext(SidebarStateContext);
  const stripe = useStripe();
  const { trackEvent } = useIntercom();

  const isSearching = searchText.length > 0;

  useEffect(() => {
    if (userData.resolvedState !== RESOLVED) return;
    GAPI.getAndUpdateMyCalendarIds(userData);
  }, [userData.resolvedState]);

  useEffect(() => {
    // Every time you navigate to this tab, we will scroll to today
    if (page === ALL_PAGES.ALL_NOTES) {
      scrollToToday();
    }
  }, [page]);

  useEffect(() => {
    if (page !== ALL_PAGES.ALL_NOTES) return;
    if (shouldWeLog(userData, isSidebarOpen)) return;
    logPageEvent(authState.userId, trackEvent, ALL_NOTES_PAGE);
  }, [page, isSidebarOpen]);

  useEffect(() => {
    if (page !== ALL_PAGES.ALL_NOTES) return () => { };
    if (!shouldWeLog(userData, isSidebarOpen)) return () => { };
    if (userData.permissions.google.calendars.length === 0) return () => { };

    fetchMeetings(startDayMin, authState.userId);
    return FirestoreMeetings
      .listenAndSetMyMeetingsByCalendarIds(
        userData.permissions.google.calendars, userData.userId,
        userData.data.email, setMeetingsFirestore,
      );
  }, [page, isSidebarOpen, userData.permissions.google.calendars]);

  // useEffect(() => {
  //   // For some reason we can't pass meetings directly into AllNotesTemplate, so
  //   // we make this hack by passing meetingsToShow, which is a copy of meetings
  //   setMeetingsToShow(meetings);
  // }, [meetings]);

  useEffect(() => {
    // We use Firestore for previous meetings and Google API for future meetings
    // Why: We assume that all interesting previous meetings will always be in our database
    // as why would you see a previous meetings if you have not made notes in them?
    // For future meetings, you might not have made notes in them yet, so we need
    // to fetch them from Google API.

    // if (Object.keys(meetingsFirestore).length > 0 ) {
    //   ConsoleImproved.log('MeetingsFirestore', meetingsFirestore);
    // }

    const meetingsArray = Object.keys(meetingsFirestore)
      .map((key) => meetingsFirestore[key])
      .flat();

    const gapiMeetingsFromFirestore = meetingsArray
      .map((meeting) => mapMeetingDataToGapiMeeting(meeting))
      .filter(GapiUtils.filterOnlyMeetingsBeforeDate(startDayMax));

    const gapiMeetingsFromAPI = meetings
      .filter(GapiUtils.filterOnlyTodayOrFutureMeetings);

    const allMeetings = [...gapiMeetingsFromFirestore, ...gapiMeetingsFromAPI]
      .filter(GapiUtils.filterOnlyUniqueMeetingsByEventId)
      .filter(GapiUtils.filterMustHaveTitle)
      .sort(GapiUtils.sortMeetingsByDate);

    setMeetingsToShow(allMeetings);
  }, [meetingsFirestore, meetings]);

  const handleGoToMeeting = async (eventId: string, calendarId: string, startDateTime: string) => {
    if (isLoadingGoToMeeting) {
      ConsoleImproved.log('Already loading go to meeting');
      return;
    }
    if (BillingUtils.blockGoToMeeting(userData, startDateTime)) {
      setIsPayWallOpen({ isOpen: true, startDateTime });
      toastInfo('Blocked', 'Upgrade to see this meeting');
      return;
    }

    setIsLoadingGoToMeeting(true);
    logAllNotesClickMeeting(authState.userId, startDateTime);
    await getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, navigate, () => { });
    setTimeout(() => {
      setPage(ALL_PAGES.CURRENT_MEETING);
      setIsLoadingGoToMeeting(false);
    }, 100); // Wait 100 to smoothly navigate the user. If not it will flicker
  };

  const onScroll = (status: ScrollState) => {
    if (status === 'scrollUpdate') {
      if (isScrolledToToday) return;
      setIsScrolledToToday(true);
    }
    if (isSearching) return;
    if (status === 'scrollTop') {
      console.log('Scrolled to the top');
      handleFetchMoreFutureMeetings();
    }
    if (status === 'scrollBottom') {
      console.log('Scrolled to the bottom');
      // handleFetchMorePreviousMeetings();
    }
  };

  const handleFetchMeetingsAgain = () => {
    fetchMeetingsAgain(startDayMin, authState.userId);
  };

  const handleFetchMoreFutureMeetings = () => {
    if (isLoadingGoToMeeting) {
      console.log('Not fetching more meetings since we are loading');
      return;
    }
    setStartDayMax((prev) => {
      fetchMeetings7DaysFromStartDay(prev + 7, authState.userId);
      return prev + 7;
    });
  };

  const handleFetchMorePreviousMeetings = () => {
    if (isLoadingGoToMeeting) {
      console.log('Not fetching more meetings since we are loading');
      return;
    }
    setStartDayMin((prev) => {
      fetchMeetings7DaysFromStartDay(prev - 7, authState.userId);
      return prev - 7;
    });
  };

  const scrollToToday = (behavior: ScrollBehavior = 'auto') => {
    const element = document.getElementById('Today');
    element?.scrollIntoView({ behavior });
  };

  const handleSearchTextChange = async (text: string) => {
    setSearchText(text);
    if (text.length < 1) {
      setTimeout(() => { scrollToToday(); }, 200);
      return;
    }
    if (userData.permissions.google.calendars.length === 0) return;
    const meetingResults = await AlgoliaAPI
      .searchMeetings(text, userData.data.email, userData.userId,
        userData.permissions.google.calendars);
    const gapiMeetingsResults = meetingResults.map(mapMeetingDataToGapiMeeting);
    setSearchResultMeetings((prev) => {
      if (prev.length > 0 && gapiMeetingsResults.length === 0) {
        scrollToToday();
      }
      Logger.logSearchedInAllNotes(text, userData.userId,
        lastTimeSearchedMeetings, setLastTimeSearchedMeetings);
      return gapiMeetingsResults;
    });
  };

  const isLoading = resolveState === PENDING || isLoadingGoToMeeting;

  useEffect(() => {
    if (isScrolledToToday) return;
    scrollToToday();
    if (isLoading) return;
    setIsScrolledToToday(true);
  }, [resolveState, isLoadingGoToMeeting, meetingsToShow]);

  useEffect(() => {
    // To scroll to today when we search and when we clear the searchbox
    [100, 1000].map((delay) => setTimeout(() => {
      scrollToToday();
    }, delay));
  }, [searchResultMeetings]);

  const handleClosePayWall = async () => {
    setIsPayWallOpen({ isOpen: false, startDateTime: '' });
    Mixpanel.Billing.logClosedPaywall(
      userData,
      trackEvent,
      StripeConstants.PAYWALL_ORIGINS.OpenMeetingOutsidePeriod,
      isPayWallOpen.startDateTime,
    );
  };

  const handleClickSubscribe = async () => {
    try {
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, StripeConstants.CHECKOUT_ORIGINS.AtEndedTrial,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const meetingsToReallyShow = isSearching ? searchResultMeetings : meetingsToShow;

  return (
    <>
      <AllNotesTemplate
        page={page}
        notes={meetingsToReallyShow}
        isLoading={isLoading}
        onAction={handleGoToMeeting}
        onScroll={onScroll}
        onClickToday={scrollToToday}
        searchText={searchText}
        onSearchTextChange={handleSearchTextChange}
        fetchMeetingsAgain={handleFetchMeetingsAgain}
        onClickFetchMoreMeetings={handleFetchMorePreviousMeetings}
        isFetchMoreMeetingsBtnEnable={false} // As we now use Firestore to get previous meetings,
      // we disable this for now
      />
      <UpgradeNowModal
        isOpen={isPayWallOpen.isOpen}
        onClickClose={handleClosePayWall}
        onClickSubscribe={handleClickSubscribe}
      />
    </>
  );
};

export default AllNotesPageCtrl;
