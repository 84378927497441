import { v4 as uuidv4 } from 'uuid';
import { SDateT, WorkspaceDb } from '../../shared/types/types';
import { dateISOObject } from '../../utils/dateUtils/date';
import StripeSubscriptionUtils from '../../external/Stripe/StripeSubscriptionUtils';

class WorkspacesUtils {
  static mapDbToWorkspaceDb = (dbData: any): WorkspaceDb => ({
    data: {
      name: dbData?.data?.name ?? '',
      description: dbData?.data?.description ?? '',
      createdBy: dbData?.data?.createdBy ?? '',
      color: dbData?.data?.color ?? '',
      picture: dbData?.data?.picture ?? '',
    },
    permissions: {
      allowedDomains: dbData?.permissions?.allowedDomains ?? [],
      members: dbData?.permissions?.members ?? [],
      editors: dbData?.permissions?.editors ?? [],
      managers: dbData?.permissions?.managers ?? [],
      admins: dbData?.permissions?.admins ?? [],
      invites: dbData?.permissions?.invites ?? [],
    },
    date: {
      created: dbData?.date?.created ?? '',
      lastUpdated: dbData?.date?.lastUpdated ?? '',
    },
    privateId: dbData?.privateId ?? '',
    billing: {
      subscription: StripeSubscriptionUtils.mapDatabaseToValue(dbData?.billing?.subscription ?? {}),
    },
  });

  static generatePrivateId = (): string => {
    // const privateId = uniqid(); // I think this is 8 character long
    const privateId = uuidv4();
    return privateId;
  }

  static createNewWorkspace = (workspaceName: string, creatorEmail: string): WorkspaceDb => {
    const currentDate: SDateT = dateISOObject();
    const newPrivateId: string = WorkspacesUtils.generatePrivateId();

    const workspace: WorkspaceDb = {
      data: {
        name: workspaceName,
        description: '',
        color: '',
        picture: '',
        createdBy: creatorEmail,
      },
      permissions: {
        /**
         * I.e. ['meetshepherd.com', 'placewise.com']
         * Then all users with emails ending with meetshepherd.com or placewise.com
         * will be allowed to join the workspace
         */
        allowedDomains: [],
        members: [creatorEmail],
        editors: [creatorEmail],
        managers: [creatorEmail],
        admins: [creatorEmail],
        invites: [],
      },
      date: {
        created: currentDate,
        lastUpdated: currentDate,
      },
      privateId: newPrivateId,
      billing: {
        subscription: StripeSubscriptionUtils.mapDatabaseToValue({}),
      },

    };
    return workspace;
  };
}

export default WorkspacesUtils;
