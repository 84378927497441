/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import ReactJson from 'react-json-view';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { UserDataContext } from '../../App';
import UserAPI from '../../database/User/UserAPI';
import StripeAPI from '../../external/Stripe/StripeAPI';
import { COLORS } from '../../shared/colors/COLORS';
import ButtonSmall from '../../shared/components/button-small';
import Header from '../../shared/components/header';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';
import BREAKPOINTS from '../../shared/CSS/CSS_Constants';
import Font from '../../shared/typography/Font';
import { toastInfo } from '../../utils/notifications';
import KnownUsersUtils from '../../utils/user/KnownUsers.ts/KnownUsersUtils';
import SubscribeToPremiumMol from '../billing/SubscribeToPremiumMol';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.md};
  /* background-color: ${COLORS.darkBlue7}; */
  border: 1px solid ${COLORS.darkBlue4};
  height: 100%;
  margin: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 5px;
  }
`;

const BillingDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
`;

interface ManageBillingPageProps {
  subject?: string,
}

// /manage
const ManageBillingPage = ({ subject }: ManageBillingPageProps) => {
  const title = subject || 'Manage Billing Admin Panel';
  const userData = useContext(UserDataContext);
  const { trackEvent } = useIntercom();

  const isKnownUser = KnownUsersUtils.isKnownUser(userData.userId);
  if (!isKnownUser) {
    return <Font font="defaultText">Not authorized. Contact harald@meetshepherd.com for access</Font>;
  }

  const handleClickSetNotInitialized = () => {
    console.log('Set user to `not_initialized`');
    toastInfo('Set user to `not_initialized`', 'ManageBillingPage');
    UserAPI.Billing.setStatusToNotInitialized(userData);
  };

  const handleSetUserToTrialing = () => {
    console.log('Set user to `trialing`');
    toastInfo('Set user to `trialing`', 'ManageBillingPage');
    UserAPI.Billing.startTrial(userData, 'manage_billing_page', trackEvent);
  };

  const setUserToTrialEnded = () => {
    console.log('Set user to `trial_ended`');
    toastInfo('Set user to `trial_ended`', 'ManageBillingPage');
    UserAPI.Billing.endTrial(userData);
  };

  const setOneLessDayInTrial = () => {
    console.log('Set one less day in Trial');
    toastInfo('Set one less day in Trial', 'ManageBillingPage');
    UserAPI.Billing.decrementTrialDays(userData);
  };

  const setOneMoreDayInTrial = () => {
    console.log('Set one more day in Trial');
    toastInfo('Set one more day in Trial', 'ManageBillingPage');
    UserAPI.Billing.incrementTrialDays(userData);
  };

  const setUserToPremium = () => {
    console.log('Set user to Premium');
    toastInfo('Set user to Premium', 'ManageBillingPage');
    UserAPI.Billing.setUserToPremium(userData);
  };

  const setUserToFreePlan = () => {
    console.log('Set user to Free plan');
    toastInfo('Set user to Free plan', 'ManageBillingPage');
    UserAPI.Billing.setUserToFreePlan(userData);
  };

  const setOneMoreMeetingUsedInFreePlan = () => {
    console.log('Add one more meeting used in Free plan');
    toastInfo('Add one more meeting used in Free plan', 'ManageBillingPage');
    UserAPI.Billing.addDummyMeetingUsed(userData);
  };

  const setOneLessMeetingUsedInFreePlan = () => {
    console.log('Remove one meeting used in Free plan');
    toastInfo('Remove one meeting used in Free plan', 'ManageBillingPage');
    UserAPI.Billing.subtractMeetingUsed(userData);
  };

  const handleGetListOfSubscriptions = async () => {
    console.log('Get list of subscriptions');
    toastInfo('Get list of subscriptions', 'ManageBillingPage');
    const data = await StripeAPI.listSubscriptions();
    const subscriptions = data.data;
    console.log(subscriptions);
    subscriptions.forEach((subscription: any) => {
      console.log(subscription);
      StripeAPI.cancelSubscription(subscription?.id);
    });
  };

  return (
    <Container>
      <Header setPage={() => { }} view={DEFAULT_HEADER_MEETING_VIEW} />
      <MainContent>
        <Font font="header700" id="title">{title}</Font>
        <br />
        <Font font="header300">
          You are now in the Plan:
        </Font>
        <Font font="header400" color="blue6">{userData.billing.status}</Font>
        <BillingDataContainer>
          <ReactJson src={userData.billing} collapsed={1} quotesOnKeys={false} />
        </BillingDataContainer>
        {/* 1. Set user to not_initialized */}
        <ButtonSmall onClick={handleClickSetNotInitialized} text="Set user to `not_initialized`" />
        {/* 2. Set user to trialing */}
        <ButtonSmall onClick={handleSetUserToTrialing} text="Set user to `trialing`" />
        {/* 3. Set user to ended trial */}
        <ButtonSmall onClick={setUserToTrialEnded} text="Set user to trial ended" />
        {/* 5. Set user to one day more untill trial ends */}
        <ButtonSmall onClick={setOneMoreDayInTrial} text="Set one more day in Trial" />
        {/* 4. Set user to one day less untill trial ends */}
        <ButtonSmall onClick={setOneLessDayInTrial} text="Set one less day in Trial" />
        {/* 6. Set user to Premium */}
        <ButtonSmall onClick={setUserToPremium} text="Set user to Premium" />
        {/* 7. Set user to Free plan */}
        <ButtonSmall onClick={setUserToFreePlan} text="Set user to Free plan" />
        {/* 8. Set one more meeting accessed in Free plan
            8.1. Show number of meetings accessed in Free plan
        */}
        <ButtonSmall onClick={setOneMoreMeetingUsedInFreePlan} text="Add one more meeting used in Free plan" />
        {/* 9. Set one less meeting accessed in Free plan */}
        <ButtonSmall onClick={setOneLessMeetingUsedInFreePlan} text="Remove one meeting used in Free plan" />
        <ButtonSmall onClick={handleGetListOfSubscriptions} text="Remove all current subscriptions" />

        <SubscribeToPremiumMol />
      </MainContent>
    </Container>
  );
};

export default ManageBillingPage;
