import React from 'react';
import styled, { css } from 'styled-components';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';
import { COLORS } from '../../../colors/COLORS';
import WorkspaceProfileIconAtom from './WorkspaceProfileIconAtom';
import Font from '../../../typography/Font';
import SpotSeparator from '../../../icons/SpotSeparator';
import SettingIcon from '../../../icons/setting';
import ReactTooltip from '../../tooltip/ReactTooltip';

interface IsSelectedProps {
  isSelected: boolean;
}

const Container = styled.div<IsSelectedProps>`
  min-width: 240px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${COLORS.gray1};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${(props) => (props.isSelected ? css`
    background-color: ${COLORS.gray4};
    cursor: default;
  ` : css`
    &:hover {
      background-color: ${COLORS.gray4};
      cursor: pointer;
    }
  `)}
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 8px;
`;

const RightBottomContainer = styled.div`
  display: flex;
  flex-direction: row;

  /* First child span */
  & > *:first-child {
    margin-right: 8px;
  }
  & > *:nth-child(2) {
    margin-right: 8px;
  }
`;

const NameContainer = styled.div`
  margin-top: 2px;
  margin-bottom: -2px;
  height: 20px;
  overflow-x: visible;
`;

const SpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RightContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 4px 4px;

  &:hover {
    cursor: pointer;
    border-radius: 8px;
    background-color: ${COLORS.gray5};
  }
`;

const TopLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface QuickSettingsWorkspaceItemProps {
  workspaceData: WorkspaceData;
  selectedWorkspaceId: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (workspaceId: string, openSettings: boolean) => void;
}

const QuickSettingsWorkspaceItemOrg = ({
  workspaceData,
  selectedWorkspaceId,
  onClick,
}: QuickSettingsWorkspaceItemProps) => {
  const arrowRef = React.useRef<HTMLDivElement>(null);

  const name = (workspaceData.name || 'Unknown').substring(0, 50);
  const s = workspaceData.numberOfMembers === 1 ? '' : 's';
  const isSelected = workspaceData.workspaceId === selectedWorkspaceId;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // If you click within the arrow, we want to open the settings as well
    if (arrowRef.current && arrowRef.current.contains(event.target as Node)) {
      console.log('%cS%c Clicked Workspace Settings in Quick Settings', 'color:cyan; font-size: 8px', '');
      onClick(workspaceData.workspaceId, true);
      return;
    }
    onClick(workspaceData.workspaceId, false);
    console.log('%cS%c Clicked Workspace in Quick Settings', 'color:cyan; font-size: 8px', '');
  };

  return (
    <Container isSelected={isSelected} onClick={handleClick}>
      <TopLeftContainer>
        <IconContainer>
          <WorkspaceProfileIconAtom
            name={name}
            color={COLORS.error7}
            size={40}
            isSelected={isSelected}
          />
        </IconContainer>
        <MiddleContainer>
          <NameContainer>
            <Font font={isSelected ? 'uiTextMediumS' : 'uiTextMedium'}>
              {name}
              {' '}
              {/* Workspace */}
            </Font>
          </NameContainer>
          <RightBottomContainer>
            <Font font="defaultSmall" color="gray7">
              {workspaceData.numberOfMembers}
              {' '}
              member
              {s}
            </Font>
            <SpotContainer>
              <SpotSeparator />
            </SpotContainer>
          </RightBottomContainer>
        </MiddleContainer>
      </TopLeftContainer>
      <ReactTooltip tip="Manage workspace">
        <RightContainer ref={arrowRef}>
          <SettingIcon fill={COLORS.gray6} />
        </RightContainer>
      </ReactTooltip>
    </Container>
  );
};

export default QuickSettingsWorkspaceItemOrg;
