import React from 'react';
import styled, { css } from 'styled-components';
import { uiTextMedium } from '../../../typography';
import { COLORS } from '../../../colors/COLORS';

interface IconProps {
  backgroundColor: string;
  fontSize: number;
  iconSize: number;
  borderSize: string;
  isSelected: boolean;
  isBig: boolean;
}

const Icon = styled.div<IconProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;    
  position: relative;
  height: ${(props) => props.iconSize}px;
  width: ${(props) => props.iconSize}px;
  border-radius: 12px;
  color: white;

  ${uiTextMedium}
  font-size: ${(props) => props.fontSize}px;

  background: ${(props) => props.backgroundColor};

  border: ${(props) => props.borderSize}px solid ${({ isSelected }) => (isSelected ? COLORS.darkBorder : COLORS.gray1)};
  
  z-index: 20;

  ${(props) => (props.isBig && css`
    height: 96px;
    width: 96px;

    border: 4px solid #FFFFFF;
    box-shadow: 0px 4px 6px -2px rgba(27, 33, 36, 0.06);
    filter: drop-shadow(0px 8px 16px rgba(27, 33, 36, 0.16));
    border-radius: 24px;
  `)}
`;
export interface Props {
  name: string,
  color?: string,
  size?: number,
  isSelected?: boolean,
  isBig?: boolean
}

const WorkspaceProfileIconAtom = ({
  name, color = COLORS.red3, size = 40, isSelected = false, isBig = false,
}: Props) => {
  const firstLetterUppercase = name.toUpperCase()[0];
  const fontSize = ((14 * (size as number)) / 24);
  const borderSize = `${(2 * (size as number)) / 24}`;

  return (
    <Icon
      backgroundColor={color}
      fontSize={fontSize}
      iconSize={size}
      borderSize={borderSize}
      isSelected={isSelected}
      isBig={isBig}
    >
      {firstLetterUppercase}
    </Icon>
  );
};

export default WorkspaceProfileIconAtom;
