/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import CreateNewWorkspaceTemplate from './CreateNewWorkspaceTemplate';
import { TeamOrPrivate } from '../../../shared/types/types';
import WorkspaceAPI from '../../../database/Workspaces/WorkspaceAPI';
import { UserDataContext } from '../../../App';
import { toastDanger } from '../../../utils/notifications';
import UserWorkspacesAPI from '../../../database/User/UserWorkspacesAPI';

interface Props {
  onClickContinue: () => void
  asTeamOrPersonal: TeamOrPrivate,
}

const CreateNewWorkspacePage = ({ onClickContinue, asTeamOrPersonal }: Props) => {
  const userData = useContext(UserDataContext);

  const handleClickContinue = async (newWorkspaceName: string) => {
    console.log('CreateNewWorkspacePage: handleClickContinue');
    const workspaceId = await WorkspaceAPI
      .createWorkspaceSimple(newWorkspaceName, userData.email, userData.userId);
    if (!workspaceId) {
      toastDanger('Failed to create workspace');
      return;
    }
    await UserWorkspacesAPI.setMyWorkspaceId(userData.userId, workspaceId);
    onClickContinue();
  };

  return (
    <CreateNewWorkspaceTemplate
      asTeamOrPersonal={asTeamOrPersonal}
      onClickCreate={handleClickContinue}
    />
  );
};

export default CreateNewWorkspacePage;
