import firebase from 'firebase';
import { captureException } from '@sentry/react';
import { Stripe } from '@stripe/stripe-js/types/stripe-js/stripe';
import StripeCoreAPI from './StripeCoreAPI';
import { StripeConstants } from './StripeConstants';
import CheckoutSessionData from './CheckoutSession';
import { StripeCheckoutSessionOrigin } from './StripeTypes';
import { IntercomTrackEvent, User } from '../../shared/types/types';
import StripeSubscriptionUtils from './StripeSubscriptionUtils';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import Mixpanel from '../../utils/analytics/Mixpanel';

/**
 * Responsible for interacting with the Stripe API
 *
 * Not responsible for updating the user billing data in Firestore
 */
class StripeAPI extends StripeCoreAPI {
  static createStriperCustomer = async (user: User): Promise<void> => {
    if (user.userId.length === 0) return;
    if (user.billing.stripeCustomerId.length > 0) return;
    StripeCoreAPI.createCustomer(user.userId);
  };

  static createAndRedirectToCheckoutSession = async (
    user: User, checkoutOrigin: StripeCheckoutSessionOrigin, quantity: number,
    intercomTrackEvent: IntercomTrackEvent, stripe: Stripe | null,
  ): Promise<any> => {
    const checkoutSession = await StripeAPI.createSubscribeCheckoutSession(user,
      checkoutOrigin, quantity);
    if (!checkoutSession.id) {
      ConsoleImproved.log('Checkoutsession id is empty');
      return;
    }
    await Mixpanel.Billing.logClickedSubscribe(
      user, intercomTrackEvent, StripeConstants.CHECKOUT_ORIGINS.DuringFreePlan,
    );
    await stripe?.redirectToCheckout({ sessionId: checkoutSession.id });
  };

  private static createSubscribeCheckoutSession = async (
    user: User, checkoutOrigin: StripeCheckoutSessionOrigin, quantity = 1,
  ): Promise<any> => {
    const sessionData = new CheckoutSessionData(
      user.billing.stripeCustomerId,
      StripeSubscriptionUtils.getDefaultMonthlyPriceId(StripeConstants.isTest),
      StripeConstants.URLS.SUCCESS_URL,
      StripeConstants.URLS.CANCEL_URL,
      checkoutOrigin,
      StripeConstants.isTest,
      user.workspaces.selectedWorkspaceId,
      quantity,
      quantity === 1,
    );
    sessionData.validate();

    const checkoutSession = await StripeCoreAPI.createCheckoutSession(sessionData);
    console.log(checkoutSession);
    return checkoutSession;
  };

  static createTestSubscriptionWithPaidTrial = async (
    userId: string, customerId: string,
  ): Promise<any> => {
    const payload = {
      userId,
      customerId,
      isTest: StripeConstants.isTest,
    };
    try {
      const result = await firebase.app()
        .functions('europe-west1')
        .httpsCallable('testCreateSubscription')(payload);
      return result.data;
    } catch (error: any) {
      console.error('Error in StripeAPI.createTestSubscriptionWithPaidTrial', { error, payload });
      captureException(error, { extra: { payload, functionName: 'StripeAPI.createTestSubscriptionWithPaidTrial' } });
      throw error;
    }
  };

  static createTrialExtensionCheckoutSession = async (
    customerId: string,
  ): Promise<any> => {
    const sessionData = {
      customerId,
      priceId: StripeSubscriptionUtils.getDefaultMonthlyPriceId(StripeConstants.isTest),
      isTest: StripeConstants.isTest,
    };

    const checkoutSession = await StripeCoreAPI.createTrialExtensionCheckoutSession(sessionData);
    console.log('Result from createTrialExtensionCheckoutSession', checkoutSession);
    return checkoutSession;
  };

  static createCheckoutSessionForAddingPaymentMethod = async (
    customerId: string,
  ): Promise<any> => {
    const checkoutSession = await StripeCoreAPI
      .createCheckoutSessionForAddingPaymentMethodCore(customerId);
    console.log(checkoutSession);
    return checkoutSession;
  };

  static createCheckoutSessionForSingleCharge = async (
    customerId: string,
    priceId: string,
  ): Promise<any> => {
    const checkoutSessionData = new CheckoutSessionData(
      customerId,
      priceId,
      StripeConstants.URLS.SUCCESS_URL,
      StripeConstants.URLS.CANCEL_URL,
      StripeConstants.CHECKOUT_ORIGINS.TrialExtension,
      StripeConstants.isTest,
    );

    const checkoutSession = await StripeCoreAPI
      .createCheckoutSessionForSingleChargeCore(checkoutSessionData);
    console.log(checkoutSession);
    return checkoutSession;
  };

  static getSubscription = async (subscriptionId: string) => {
    const subscription = await StripeCoreAPI.getSubscription(subscriptionId);
    return subscription;
  }

  static cancelSubscription = async (subscriptionId: string) => {
    const subscription = await StripeCoreAPI.cancelSubscription(subscriptionId);
    return subscription;
  }

  static createPortalSession = async (user: User, isTest: boolean = false) => {
    const data = await StripeCoreAPI.createPortalSessionCore(user.billing.stripeCustomerId, isTest);
    return data;
  };

  static listSubscriptions = async () => {
    const data = await StripeCoreAPI.listSubscriptionsCore();
    return data;
  };

  static updateSubscriptionQuantity = async (
    subscriptionId: string, subscriptionItemId: string, quantity: number,
  ) => {
    const data = await StripeCoreAPI.updateSubscriptionQuantityCore(
      subscriptionId, subscriptionItemId, quantity,
    );
    return data;
  }
}

export default StripeAPI;
