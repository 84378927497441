import React from 'react';
import styled, { css } from 'styled-components';
import ButtonDivStyle from '../../../pages/meeting/tab/utils/index';
import { messageText } from '../../typography';
import { COLORS } from '../../colors/COLORS';

interface ButtonProps {
  active: boolean,
}

const Button = styled(ButtonDivStyle) <ButtonProps>`
  ${messageText};
  overflow: hidden;
  white-space: nowrap;
  height: fit-content;
  width: fit-content;
  border-radius: 8px;
  z-index: 1;
  background: none;
  user-select: none;

  padding: 6px 12px;

  svg {
    margin-right: 0px !important;
    padding: 4px;
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: ${COLORS.gray1};
        background:${COLORS.gray10};
        border-radius: 8px;
      `;
    }

    return css`
      color: ${COLORS.darkBlue4};
      &:hover {
        color: ${COLORS.darkBlue6};
        background: ${COLORS.darkBlue1};
      }
    `;
  }};
`;

interface ButtonTabAtomProps {
  isActive?: boolean,
  onClick?: () => void,
  children: React.ReactNode,
}

const ButtonTabAtom = ({ isActive = true, onClick = () => {}, children }: ButtonTabAtomProps) => (
  <Button active={isActive} onClick={onClick}>
    {children}
  </Button>
);

export default ButtonTabAtom;
