/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { BrowserRouter } from 'react-router-dom';
import {
  CompatRouter, Routes, Route, Navigate,
} from 'react-router-dom-v5-compat';

import firebase from 'firebase';
import { ReactNotifications } from 'react-notifications-component';
import styled, { css } from 'styled-components';
import ROUTES from './routes/ROUTES_CONSTANTS';
import initializeGoogleAPI from './utils/google/GoogleCalendarSetup';
import { AuthState, User } from './shared/types/types';
import './utils/firebase';
import { LOGGED_IN_STATE, UNRESOLVED_STATE } from './utils/enums';
import AuthenticatedRoute from './shared/components/routes';
import GoogleMeet from './pages/googleMeet';
import GlobalStyle from './utils/globalStyle';
import {
  defaultAuthContext,
  pendingUser,
} from './utils/user/UserDataUtils';
import GoogleCalendar from './pages/googleCalendar';
import IntercomContainer from './IntercomContainer';
import { startTrackingEvent, stopTrackingEvent } from './utils/analytics/eventLogger';
import ListenToRouteMessageContainer from './ListenToRouteMessageContainer';
import ListenToSidebarMessageContainer from './ListenToSidebarMessageContainer';
import { setMixpanelUserAndLogAppStart } from './utils/mixpanel';
import 'react-notifications-component/dist/theme.css';
import './shared/typography/font-faces.css';
import ChromeExtensionEventLogger from './ChromeExtensionEventLogger';
import PreviousMeetingNotes from './shared/components/previous-meeting-notes';

import TutorialVideo from './pages/tutorialVideo';
import AllPages from './pages/all-pages';
import NotionRedirectPage from './pages/NotionRedirectPage/NotionRedirectPage';
import TrelloRedirectPage from './pages/TrelloRedirectPage/TrelloRedirectPage';
import SlackRedirectPage from './pages/SlackRedirectPage/SlackRedirectPage';
import IvanTestingPage from './pages/testing/IvanTestingPage';
import HaraldTestingPage from './pages/testing/HaraldTestingPage';
import ShaokunTestingPage from './pages/testing/ShaokunTestingPage';
import MatthewTestingPage from './pages/testing/MatthewTestingPage';
import AsishTestingPage from './pages/testing/AsishTestingPage';
import GuestTestingPage from './pages/testing/GuestTestingPage';
import logAppAnalytics, { logPlatformAnalytics, shouldWeLog } from './utils/analytics/functions';
import setSentryUser from './utils/analytics/sentry';
import DetectingOperatingSystemContainer from './utils/context/OperatingSystemContext';
import ShepherdLogoLoadingPage from './shared/components/loading/shepherd-logo-loading-page';
import DetectInternetConnectionContainer from './utils/context/DetectInternetConnectionContainer';
import Signin from './pages/signin';
import { UIProvider } from './pages/all-pages/ui-context/uiContext';

import 'prosemirror-image-plugin/dist/styles/common.css';
import 'prosemirror-image-plugin/dist/styles/withResize.css';
import 'prosemirror-image-plugin/dist/styles/sideResize.css';
import 'prosemirror-image-plugin/dist/styles/withoutResize.css';
import SErrorBoundaryContainer from './pages/SErrorBoundary/SErrorBoundaryContainer';
import FullScreenLeftSidebar from './shared/components/full-screen-sidebar/FullScreenLeftSidebar';
import { CSS_CONSTANTS } from './shared/CSS/CSS_Constants';
import ManageBillingPage from './pages/ManageBilling/ManageBillingPage';
import SuccessfullPaymentPageCtrl from './pages/SuccessfullPayment/SuccessfullPaymentPageCtrl';
import CancelPaymentPage from './pages/CancelPaymentPage/CancelPaymentPage';
import UserAPI from './database/User/UserAPI';
import PrivateRoute from './routes/PrivateRoute';
import PersonalOnboarding from './pages/onboarding/personal';
import WorkspaceOnboarding from './pages/onboarding/workspace';
import OnboardingWelcomeScreens from './pages/onboarding/welcome';
import FirstMeeting from './pages/onboarding/first-meeting';
import MeetingsCacheClass from './utils/MeetingsCache/MeetingsCache';
import ManageWorkspacesPage from './pages/ManageWorkspaces/ManageWorkspacesPage';
import UserSmart from './shared/classes/UserSmart';
import WorkspaceAPI from './database/Workspaces/WorkspaceAPI';
import WorkspaceData from './database/Workspaces/WorkspaceData';
import InvitePage from './pages/all-pages/invite/InvitePage';

const EntirePageContainer = styled.div`
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FullWidthContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LeftContainer = styled.div`
  width: calc(${CSS_CONSTANTS.LEFT_MENU_SIDEBAR_WIDTH} - 1px);
  height: 100vh;
  flex-shrink: 0;
  position: relative; // for the position: absolute of the flower
  overflow: hidden;
  background-color: #1B2124 !important;
  @media only screen and (max-width: 1280px){
    display: none;
  }
`;

const RightContainer = styled.div`
  flex-grow: 1;
  background: white;
  width: 100%;

  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1280px){
    border-left: 1px solid #32373A;
  }
`;

export const AuthContext = createContext<AuthState>(defaultAuthContext);
export const UserDataContext = createContext<UserSmart>(UserSmart.createPending());

export const MeetingsCache = new MeetingsCacheClass();

function App() {
  const [authState, setAuthState] = useState<AuthState>(defaultAuthContext);
  const [userData, setUserData] = useState<UserSmart>(UserSmart.createPending());
  const [workspace, setWorkspace] = useState<WorkspaceData>(WorkspaceData.createDummy());
  const [hasSidebarOpened, setHasSidebarOpened] = useState<boolean>(true);

  useEffect(() => {
    // This event contributes way too much to our Mixpanel Events.
    // logNotesTabEvent(authState.userId, OPEN_EVENT, meetingSection);
    // startTrackingEvent('appLoad');
    initializeGoogleAPI(setAuthState);
  }, []);

  useEffect(() => {
    if (authState.userState !== 'loggedIn') return () => { };
    firebase.analytics().setUserId(authState.userId);
    setSentryUser(authState);
    setMixpanelUserAndLogAppStart(authState);
    return UserAPI.General.listenToMyData(authState, setUserData);
  }, [authState]);

  useEffect(() => {
    if (!shouldWeLog(userData, hasSidebarOpened)) return;
    logAppAnalytics(authState, hasSidebarOpened);
    logPlatformAnalytics(authState.userId);
  }, [userData.resolvedState, hasSidebarOpened]);

  useEffect(() => {
    if (userData.friendListV2.resolvedState !== 'resolved') return;
    console.log('Got new User Data', userData);
  }, [userData]);

  useEffect(() => {
    if (userData.resolvedState !== 'resolved') return;
    if (!workspace.isValid()) return;
    if (_.isEqual(workspace, userData.workspace)) {
      console.log('Workspace is the same, not updating', { userWorkspaceData: workspace, userDataWorkspace: userData.workspace });
      return;
    }
    console.log('Got new User Workspace Data, updating');
    setUserData((prevUserData) => new UserSmart(prevUserData, workspace));
  }, [workspace]);

  useEffect(() => {
    if (!userData.selectedWorkspaceId) return () => {};
    return WorkspaceAPI.listenToWorkspace(
      userData.selectedWorkspaceId,
      setWorkspace, () => {},
    );
  }, [userData.selectedWorkspaceId]);

  const isUnresolved = authState.userState === UNRESOLVED_STATE;

  if (isUnresolved) {
    return <ShepherdLogoLoadingPage />;
  }

  // This event contributes way too much to our Mixpanel Events.
  // logNotesTabEvent(authState.userId, OPEN_EVENT, meetingSection);
  // stopTrackingEvent('appLoad');

  return (
    <AuthContext.Provider value={authState}>
      <UserDataContext.Provider value={userData}>
        <DetectingOperatingSystemContainer>
          <DetectInternetConnectionContainer>
            <IntercomContainer>
              <ListenToSidebarMessageContainer setHasSidebarOpened={setHasSidebarOpened}>
                <UIProvider>
                  <GlobalStyle />
                  <ReactNotifications />
                  <ChromeExtensionEventLogger />
                  <EntirePageContainer>
                    <BrowserRouter>
                      <CompatRouter>
                        <FullWidthContainer>
                          <LeftContainer>
                            <FullScreenLeftSidebar />
                          </LeftContainer>
                          <RightContainer id="rightContainer">
                            <ListenToRouteMessageContainer>
                              <SErrorBoundaryContainer>
                                <Routes>
                                  {/* When adding new routes, you need to do it here.
                  You cannot make a new file and with additional routes and do it in that file. */}
                                  <Route path={ROUTES.signinPath} element={<Signin />} />
                                  <Route
                                    path={ROUTES.homePath}
                                    element={(
                                      <PrivateRoute>
                                        <AllPages />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.dashboardPath}
                                    element={(
                                      <PrivateRoute>
                                        <AllPages />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path="/meeting/:meetingId"
                                    element={(
                                      <PrivateRoute>
                                        <AllPages />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.homePath}
                                    element={(
                                      <PrivateRoute>
                                        <AllPages />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.onboardingPersonalPath}
                                    element={(
                                      <PrivateRoute>
                                        <PersonalOnboarding />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.onboardingWorkspacePath}
                                    element={(
                                      <PrivateRoute>
                                        <WorkspaceOnboarding />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.onboardingWelcomePath}
                                    element={(
                                      <PrivateRoute>
                                        <OnboardingWelcomeScreens />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.invites}
                                    element={(
                                      <PrivateRoute>
                                        <InvitePage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.onboardingFirstMeetingPath}
                                    element={<FirstMeeting />}
                                  />
                                  {/* <Route
                                    path={ROUTES.onboardingPath}
                                    element={(
                                      <PrivateRoute>
                                        <Navigate to={ROUTES.onboardingPersonalPath} />
                                      </PrivateRoute>
                                    )}
                                  /> */}
                                  {/* TODO: Make sure onboarding paths are correct */}
                                  {/* <Route
                                    path={ROUTES.onboardingPath}
                                    element={(
                                      <PrivateRoute>
                                        <OnboardingRoutes />
                                      </PrivateRoute>
                                    )}
                                  /> */}
                                  <Route
                                    path={ROUTES.previousMeetingPath}
                                    element={(
                                      <PrivateRoute>
                                        <PreviousMeetingNotes />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.scratchpad}
                                    element={(
                                      <PrivateRoute>
                                        <AllPages />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.manage_billing}
                                    element={(
                                      <PrivateRoute>
                                        <ManageBillingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.manage_workspaces}
                                    element={(
                                      <PrivateRoute>
                                        <ManageWorkspacesPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.successfullPayment}
                                    element={(
                                      <PrivateRoute>
                                        <SuccessfullPaymentPageCtrl />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.cancelPayment}
                                    element={(
                                      <PrivateRoute>
                                        <CancelPaymentPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.tutorialVideoPath}
                                    element={(
                                      <PrivateRoute>
                                        <TutorialVideo />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.slackRedirectPath}
                                    element={(
                                      <PrivateRoute>
                                        <SlackRedirectPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.notionRedirectPath}
                                    element={(
                                      <PrivateRoute>
                                        <NotionRedirectPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.trelloRedirectPath}
                                    element={(
                                      <PrivateRoute>
                                        <TrelloRedirectPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.ivanTestingPath}
                                    element={(
                                      <PrivateRoute>
                                        <IvanTestingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.haraldTestingPath}
                                    element={(
                                      <PrivateRoute>
                                        <HaraldTestingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.shaokunTestingPath}
                                    element={(
                                      <PrivateRoute>
                                        <ShaokunTestingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.matthewTestingPath}
                                    element={(
                                      <PrivateRoute>
                                        <MatthewTestingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.asishTestingPath}
                                    element={(
                                      <PrivateRoute>
                                        <AsishTestingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.guestTestingPath}
                                    element={(
                                      <PrivateRoute>
                                        <GuestTestingPage />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.GOOGLE_CALENDAR_ROUTE}
                                    element={(
                                      <PrivateRoute>
                                        <GoogleCalendar />
                                      </PrivateRoute>
                                    )}
                                  />
                                  <Route
                                    path={ROUTES.GOOGLE_MEET_ROUTE}
                                    element={(
                                      <PrivateRoute>
                                        <GoogleMeet />
                                      </PrivateRoute>
                                    )}
                                  />
                                  {/* <Route
                                    path={'/*'}
                                    element={(
                                      <PrivateRoute>
                                        <AllPages />
                                      </PrivateRoute>
                                    )}
                                  /> */}

                                  {/* <AuthenticatedRoute
                                    exact
                                    path={ROUTES.homePath}
                                    component={AllPages}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.dashboardPath}
                                    component={AllPages}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.onboardingPath}
                                    component={OnboardingRoutes}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.previousMeetingPath}
                                    component={PreviousMeetingNotes}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path="/scratchpad"
                                    component={AllPages}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.manage_billing}
                                    component={ManageBillingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    component={SuccessfullPaymentPageCtrl}
                                    path={ROUTES.successfullPayment}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    component={CancelPaymentPage}
                                    path={ROUTES.cancelPayment}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.tutorialVideoPath}
                                    component={TutorialVideo}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.slackRedirectPath}
                                    component={SlackRedirectPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.notionRedirectPath}
                                    component={NotionRedirectPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.trelloRedirectPath}
                                    component={TrelloRedirectPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.ivanTestingPath}
                                    component={IvanTestingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.haraldTestingPath}
                                    component={HaraldTestingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.shaokunTestingPath}
                                    component={ShaokunTestingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.matthewTestingPath}
                                    component={MatthewTestingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.asishTestingPath}
                                    component={AsishTestingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.guestTestingPath}
                                    component={GuestTestingPage}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.GOOGLE_CALENDAR_ROUTE}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                    component={GoogleCalendar}
                                  />
                                  <AuthenticatedRoute
                                    path={ROUTES.GOOGLE_MEET_ROUTE}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                    component={GoogleMeet}
                                  />
                                  <AuthenticatedRoute
                                    path={'/*'}
                                    exact
                                    component={AllPages}
                                    isAuthenticated={isAuthenticated}
                                    isOnboarded={isOnboarded}
                                  /> */}
                                </Routes>
                              </SErrorBoundaryContainer>
                            </ListenToRouteMessageContainer>
                          </RightContainer>
                        </FullWidthContainer>
                      </CompatRouter>
                    </BrowserRouter>
                  </EntirePageContainer>
                </UIProvider>
              </ListenToSidebarMessageContainer>
            </IntercomContainer>
          </DetectInternetConnectionContainer>
        </DetectingOperatingSystemContainer>
      </UserDataContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
