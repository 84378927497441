/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import InviteByEmailInput from './InviteByEmailInput';
import ResolvedStateSmartExpanded from '../../../../utils/ResolvedState/ResolvedStateSmartExpanded';
import { UserDataContext } from '../../../../App';
import WorkspaceAPI from '../../../../database/Workspaces/WorkspaceAPI';
import cfSearchPublicUserDataV2ByEmail from '../../../../external/publicUserData/PublicUserDataAPI';
import { toastInfo } from '../../../../utils/notifications';

const Container = styled.div``;

interface InviteByEmailInputProps {
  onReset: () => void,
  showReset?: boolean,
}

const InviteByEmailInputCtrl = ({ onReset, showReset = true }: InviteByEmailInputProps) => {
  const userData = useContext(UserDataContext);
  const [status, setStatus] = useState<ResolvedStateSmartExpanded>(ResolvedStateSmartExpanded.NULL);

  const handleClickInvite = async (email: string) => {
    console.log('InviteByEmailInputCtrl: handleClickInvite: email:', email);
    if (userData.getMyWorkspacePermission() !== 'admin') {
      toastInfo('Only admins can invite new members');
      return;
    }
    setStatus(ResolvedStateSmartExpanded.PENDING);
    const toBeInvitedUser = await cfSearchPublicUserDataV2ByEmail(email);
    const workspaceId = userData.selectedWorkspaceId;
    const result = await WorkspaceAPI.inviteMemberToWorkspace(
      workspaceId, userData.workspace.name, email, toBeInvitedUser.data.name || '',
      userData.email, userData.name, userData.userId,
    );
    if (result.isError) {
      setStatus(ResolvedStateSmartExpanded.REJECTED);
    }
    if (result.isResolved) {
      setStatus(ResolvedStateSmartExpanded.RESOLVED);
      setTimeout(() => {
        setStatus(ResolvedStateSmartExpanded.NULL);
      }, 2000);
    }
  };

  const handleReset = () => {
    setStatus(ResolvedStateSmartExpanded.NULL);
    onReset();
  };

  return (
    <InviteByEmailInput
      onClickInvite={handleClickInvite}
      status={status}
      onReset={handleReset}
      showReset={showReset}
    />
  );
};

export default InviteByEmailInputCtrl;
