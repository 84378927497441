import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../../../App';
import UserAPI from '../../../../database/User/UserAPI';
import ConsoleImproved from '../../../../shared/classes/ConsoleImproved';
import { toastDanger } from '../../../../utils/notifications';
// import TrialEndedModal from './TrialEndedModal';
import BillingModalAreYouSure from './AreYouSureModal';
import YouAreNowOnTheFreePlanModal from './YouAreNowOnTheFreePlan/YouAreNowOnTheFreePlan';
import StripeAPI from '../../../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../../../external/Stripe/StripeConstants';
import Mixpanel from '../../../../utils/analytics/Mixpanel';
import TrialEndedOfferExtensionModal from '../TrialEndedOfferExtensionModal/TrialEndedOfferExtensionModal';
import StripeSubscriptionUtils from '../../../../external/Stripe/StripeSubscriptionUtils';
import SlackBoltAPI from '../../../../external/SlackBolt/SlackBoltAPI';
import { TIMES } from '../../../../utils/constants';

const TrialEndedModalCtrl = () => {
  const userData = useContext(UserDataContext);
  const stripe = useStripe();
  const { trackEvent } = useIntercom();
  const [state, setState] = useState<'closed' | 'trialEnded' | 'areYouSure' | 'freePlanOpen'>('closed');

  const checkIfTrialEnded = async () => {
    if (userData.hasTrialEnded()) {
      console.log('Trial ended, opening modal');
      setState('trialEnded');
    } else {
      console.log('Trial not ended, closing modal');
      setState('closed');
    }
  };

  useEffect(() => {
    // This block is to open the trial has ended modal when needed.
    // It checks every 30 minutes if the trial has ended.
    // If it has, it opens the modal.
    // If the trial has not ended, we close the modal
    // We want to check every 30 minutes because the trial can end at any time
    // and also if the trial ends, and we have Shepherd open in multiple tabs
    // the modal will open in all tabs, and if you in one of the tabs
    // goes through the flow, we want to afterwards close the
    // modal in the other tabs. This is why we have the else block
    console.log('1');
    if (userData.resolvedState !== 'resolved') return () => {};
    checkIfTrialEnded();

    const interval = setInterval(() => {
      checkIfTrialEnded();
    }, TIMES.THIRTY_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, [userData.billing, userData.resolvedState, userData.workspace]);

  const handleClickSubscribe = async () => {
    ConsoleImproved.log('Clicked Subscribe', userData);

    try {
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, StripeConstants.CHECKOUT_ORIGINS.AtEndedTrial,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleClickFree = async () => {
    setState('freePlanOpen');
  };

  const handleClickClose = async () => {
    await UserAPI.Billing.setUserToFreePlan(userData);
    await Mixpanel.Billing.logClickTryForFree(userData, trackEvent, 'trial_ended');
    setState('closed');
  };

  const handleClickExtendTrial = async () => {
    console.log('handleClickExtendTrial');
    const customerId = userData.billing.stripeCustomerId;
    const priceId = StripeSubscriptionUtils.getTrialExtensionPriceId(StripeConstants.isTest);
    await SlackBoltAPI.logClickedTrialExtension(userData, 'atEndedTrial', 'trialEndedModal');

    const result = await StripeAPI.createCheckoutSessionForSingleCharge(customerId, priceId);
    await stripe?.redirectToCheckout({ sessionId: result.id });
    console.log(result?.url);
  };

  const onClickBack = () => {
    setState('trialEnded');
  };

  const handleSetAreYouSure = () => {
    setState('areYouSure');
  };

  if (state === 'closed') return null;

  if (state === 'freePlanOpen') {
    return (
      <YouAreNowOnTheFreePlanModal
        isOpen
        onClickBack={onClickBack}
        onClickClose={handleClickClose}
      />
    );
  }

  if (state === 'areYouSure') {
    return (
      <BillingModalAreYouSure isOpen={state === 'areYouSure'} onClickBack={onClickBack} onClickClose={handleClickFree} />
    );
  }
  return (
    <>
      <TrialEndedOfferExtensionModal
        isOpen
        onClickExtend={handleClickExtendTrial}
        onClickSubscribe={handleClickSubscribe}
        onClickFreePlan={handleSetAreYouSure}
      />
    </>
  );

  // return (
  //   <>
  //     <TrialEndedModal
  //       isOpen
  //       onClickSubscribe={handleClickSubscribe}
  //       onClickFreePlan={handleSetAreYouSure}
  //     />
  //   </>
  // );
};

export default TrialEndedModalCtrl;
