/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { uuid4 } from '@sentry/utils';
import Modal from '.';
import {
  gray1, gray3, gray4, darkBlue1, darkBlue3,
} from '../../colors/COLORS';
import {
  header300, header700, uiText, uiTextMedium,
} from '../../typography';
import ButtonSmall from '../button-small';
import FileUploadChip, { FileUploadChipProps } from './FileUploadChip';
import startFirebaseUpload from '../prosemirror/logic/images/start-firebase-upload';
import MediaPaginator from '../MediaPaginator';
import { pixbayFetch, pixbayToMediaResources } from '../../logic/MediaSearchParams/pixbay';
import { MediaResource } from '../../logic/MediaSearchParams/types';
import { getStorageRef } from '../../../utils/firebase';

const Dropzone = styled.div`
  width: 100%;
  background-color: #F7F9FB;
  padding: 32px;
`;

const DropzoneContent = styled.div`
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 24px;
  border: 2px dashed #B7BECF;
  padding: 54px 0 54px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > * {
    box-sizing: content-box;
  }
`;

const SearchzoneContent = styled.div`
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 0 0 54px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > * {
    box-sizing: content-box;
  }
`;

const DropzoneHeader = styled.h2`
  color: #595959;
  margin-top: 0;
  margin-bottom: 4px;
  ${header700}
`;

const DropzoneSubheader = styled.h4`
  color: #595959;
  margin-top: 4px;
  margin-bottom: 0;
  ${header300}
`;

const ButtonContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 24px;
  gap: 8px;
`;

const Line = styled.div`
  background:  ${darkBlue1};
  height: 1px;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

const ClearAllButton = styled.p`
  color: ${darkBlue3};
  ${uiTextMedium};
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  margin: 0;
  padding: 6px 18px 6px 0px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

interface FileUploadModalProps {
  isOpen: boolean;
  setModalClosed: () => void;
  path: string,
  schema: Schema;
  view: EditorView;
}

// TODO: Find a way to incorporate overflow:scroll
//  Too many files cause the modal to go 'out of bounds'

const FileUploadModal = ({
  isOpen, setModalClosed, path, view, schema,
}: FileUploadModalProps) => {
  const {
    getRootProps, getInputProps, open, acceptedFiles, isDragActive,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  interface ToUpload extends FileUploadChipProps {
    file: File;
    started: boolean;
    uniqueKey: string;
  }

  const [toUpload, setToUpload] = useState<(ToUpload)[]>([]);

  interface UniqueUrl {
    url: string;
    uniqueKey: Object;
  }

  const [urls, setUrls] = useState<UniqueUrl[]>([]);

  const [webSearchToggle, setWebSearchToggle] = useState<boolean>(false);

  const clean = () => {
    setWebSearchToggle(false);
    setUrls([]);
    setToUpload([]);
  };

  const closeAndClean = () => {
    clean();
    setModalClosed();
  };

  const toggleWebSearch = () => {
    setWebSearchToggle(!webSearchToggle);
  };

  const insertMediaSelection = (resource: MediaResource) => {
    const transaction = view.state.tr;
    transaction.insert(
      view.state.selection.$from.pos,
      schema.nodes.image.create({ src: resource.webformatURL }),
    );
    view.dispatch(transaction);
    closeAndClean();
  };

  useEffect(() => {
    acceptedFiles.forEach((file, index) => {
      setToUpload((existing) => [...existing, {
        started: false,
        file,
        filename: file.name,
        currentFileSize: 0 as number,
        fullFileSize: file.size,
        uniqueKey: uuid4(),
      }]);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    toUpload.forEach((toUploadData, index) => {
      if (!toUploadData.started) {
        const newData = [...toUpload];
        newData[index].started = true;
        setToUpload([...newData]);

        startFirebaseUpload({
          file: toUploadData.file,
          getStorageRef,
          path,
          onProgress: (_progress, total, transfered) => {
            if (toUpload[index].started) {
              // eslint-disable-next-line no-shadow
              const newData = [...toUpload];
              newData[index].fullFileSize = total!;
              newData[index].currentFileSize = transfered!;
              setToUpload([...newData]);
            }
          },
          onCompleted: async (ref) => {
            const URL = await ref.getDownloadURL();
            setUrls((oldUrls) => [...oldUrls, {
              url: URL,
              uniqueKey: toUploadData.uniqueKey,
            }]);
          },
        });
      }
    });
  }, [toUpload]);

  const insertImages = useCallback(
    () => {
      const transaction = view.state.tr;
      urls.forEach((url) => {
        transaction.insert(
          view.state.selection.$from.pos,
          schema.nodes.image.create({ src: url.url, alt: '' }),
        );
      });
      view.dispatch(transaction);
      closeAndClean();
    }, [urls],
  );

  const selfDestruct = (key: string) => () => {
    setToUpload((existing) => existing.filter((value) => value.uniqueKey !== key));
    setUrls((existing) => existing.filter((value) => value.uniqueKey !== key));
  };

  const files = toUpload.map((toUploadData) => (
    <FileUploadChip
      cancel={selfDestruct(toUploadData.uniqueKey)}
      filename={toUploadData.filename}
      currentFileSize={toUploadData.currentFileSize}
      fullFileSize={toUploadData.fullFileSize}
      key={toUploadData.uniqueKey}
    />
  ));

  return (
    <Modal
      title="Insert Media"
      isOpen={isOpen}
      setModalClosed={setModalClosed}
    >
      <Dropzone
        {...getRootProps({ className: 'dropzone' })}
        style={{
          backgroundColor: webSearchToggle ? '#FFFFFF' : '#F7F9FB',
        }}
      >
        <input
          {...getInputProps()}
        />
        {
          webSearchToggle === false
            && (
            <DropzoneContent>
              <DropzoneHeader>
                { isDragActive
                  ? 'Drop the files here'
                  : 'Drag and Drop' }
              </DropzoneHeader>
              <DropzoneSubheader>
                { isDragActive
                  ? 'to start uploading'
                  : 'or upload images, videos, and GIFs' }
              </DropzoneSubheader>
              <ButtonContainer>
                <ButtonSmall
                  onClick={open}
                  text="Upload from Computer"
                />
                <ButtonSmall
                  onClick={toggleWebSearch}
                  text="Search web"
                  isOutline
                />
              </ButtonContainer>
            </DropzoneContent>
            )
        }
        {
          webSearchToggle === true
            && (
              <SearchzoneContent>
                <DropzoneHeader>
                  Search images
                </DropzoneHeader>
                <DropzoneSubheader>
                  and select the desired resource
                </DropzoneSubheader>
                <ButtonContainer>
                  <ButtonSmall
                    onClick={open}
                    text="Upload from Computer"
                    isOutline
                  />
                  <ButtonSmall
                    onClick={toggleWebSearch}
                    text="Search web"
                  />
                </ButtonContainer>
                <MediaPaginator
                  hasPagination={false}
                  // paginateNext={() => {}}
                  // paginatePrevious={() => {}}
                  mediaFetcher={pixbayFetch}
                  responseParser={pixbayToMediaResources}
                  mediaSelection={insertMediaSelection}
                />
              </SearchzoneContent>
            )
        }
        {files}
      </Dropzone>
      <Line />
      <ButtonRow>
        <ClearAllButton
          onClick={clean}
        >
          Clear All
        </ClearAllButton>
        <Spacer />
        <ButtonSmall
          onClick={closeAndClean}
          text="Cancel"
          isOutline
        />
        <ButtonSmall
          onClick={insertImages}
          isDisabled={urls.length === 0}
          text="Insert"
        />
      </ButtonRow>
    </Modal>
  );
};

export default FileUploadModal;
