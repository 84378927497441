import WorkspaceData from '../../database/Workspaces/WorkspaceData';
import { pendingUser } from '../../utils/user/UserDataUtils';
import { User, WorkspacePermissionExpanded } from '../types/types';
import UserClass from './UserClass';

class UserSmart extends UserClass {
  workspace: WorkspaceData;

  constructor(value: User, workspace: WorkspaceData) {
    super(value);
    this.workspace = workspace;
  }

  get name(): string {
    return this.data.name;
  }

  get email(): string {
    return this.data.email;
  }

  get selectedWorkspaceId(): string {
    return this.workspaces.selectedWorkspaceId;
  }

  isResolved(): boolean {
    return this.resolvedState === 'resolved';
  }

  isOnPremium(): boolean {
    if (this.billing.isOnPremiumPlan()) return true;
    if (this.workspace.isOnPremium()) return true;
    return false;
  }

  isOnTrial(): boolean {
    if (this.isOnPremium()) return false;
    if (this.billing.isOnTrial()) return true;
    return false;
  }

  isOnFreePlan(): boolean {
    if (this.isOnPremium()) return false;
    if (this.isOnTrial()) return false;
    if (this.billing.isOnFreePlan()) return true;
    return false;
  }

  isBillingInitialized(): boolean {
    if (this.isOnPremium()) return true;
    return this.billing.isBillingInitialized();
  }

  hasTrialEnded(): boolean {
    if (this.isOnPremium()) return false;
    if (this.billing.hasTrialEnded()) return true;
    return false;
  }

  /**
   * Check if you have editor rights on currently selected workspace
   */
  isWorkspaceEditor(): boolean {
    return this.workspace.isEditor(this.email);
  }

  /**
   * What is my permission on currently selected workspace
   */
  getMyWorkspacePermission(): WorkspacePermissionExpanded {
    return this.workspace.getPermission(this.email);
  }

  static async create(user: User) {
    if (!user.workspaces.selectedWorkspaceId) {
      const workspace = WorkspaceData.createDummy();
      return new UserSmart(user, workspace);
    }
    const workspace = await WorkspaceData.get(user.workspaces.selectedWorkspaceId, user.userId);
    return new UserSmart(user, workspace);
  }

  static createPending() {
    const workspace = WorkspaceData.createDummy();
    return new UserSmart(pendingUser, workspace);
  }

  static createDummy(name: string = '', email: string = '', workspaceName: string = '', numberOfMembers: number = 1) {
    const workspace = WorkspaceData.createDummy(workspaceName, numberOfMembers);
    const user = {
      ...pendingUser,
      data: { ...pendingUser.data, name, email },
    } as User;
    return new UserSmart(user, workspace);
  }
}

export default UserSmart;
