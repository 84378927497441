import { User } from '../../shared/types/types';
import Logger from '../../utils/analytics/Logger/Logger';
import { USER_PATH } from '../FirebaseConstants';
import WorkspaceAPI from '../Workspaces/WorkspaceAPI';
import WorkspaceData from '../Workspaces/WorkspaceData';
import WorkspacesUtils from '../Workspaces/WorkspacesUtils';
import UserAPICore from './UserAPICore';

class UserWorkspacesAPI extends UserAPICore {
  static setupWorkspaceIfNotSetup = async (user: User) => {
    console.log('setupWorkspaceIfNotSetup');
    // 1. Check if user has selected a workspace
    // 2. If not, check if there is any workspaces the user is a member of
    // 3. If there is, select the first one
    // 4. If there is not, create a new workspace and select it

    //  1. Check if user has selected a workspace
    if (user.userId.length === 0) return;
    if (user.workspaces.selectedWorkspaceId) {
      console.log('User has selected a workspace');
      return;
    }

    // 2. If not, check if there is any workspaces the user is a member of
    const myWorkspaces = await WorkspaceAPI.getWorkspaces(user.data.email);

    // 3. If there is, select the first one
    if (myWorkspaces.length > 0) {
      console.log('User is a member of at least one workspace');
      const firstWorkspace = myWorkspaces[0];
      await UserWorkspacesAPI.setMyWorkspaceId(user.userId, firstWorkspace.workspaceId);
      return;
    }

    // 4. If there is not, create a new workspace and select it
    const newWorkspace = await UserWorkspacesAPI.createInitialPrivateWorkspace(user);
    await UserWorkspacesAPI.setMyWorkspaceId(user.userId, newWorkspace.workspaceId);
  };

  static setMyWorkspaceId = async (userId: string, workspaceId: string) => {
    if (!userId) throw new Error('userId is required');
    if (!workspaceId) throw new Error('workspaceId is required');
    console.log('setMyWorkspaceId', { userId, workspaceId });
    Logger.Workspaces.logSetMyWorkspaceId(userId, workspaceId);
    const updates = {
      [USER_PATH.workspaces.selectedWorkspaceId]: workspaceId,
    };
    return UserAPICore.updateUser(userId, updates, USER_PATH.workspaces.selectedWorkspaceId);
  };

  /**
   * If we are deleting the workspace in which is also the selected workspace
   * for a user, we need to select a different workspace for the user.
   * If we set the selected workspace to be an empty string, we will in
   * `UserGeneralAPI.listenToMyData()` set the selected workspace to either be
   * one of the workspaces the user is a member of, or if none, create a new
   * workspace and select it.
   */
  static resetSelectedWorkspaceIdIfItsGoingToBeDeleted = async (
    user: User, workspaceIdToBeDeleted: string,
  ) => {
    if (workspaceIdToBeDeleted === user.workspaces.selectedWorkspaceId) {
      Logger.Workspaces.logResetSelectedWorkspaceId(user.userId);
      const updates = {
        [USER_PATH.workspaces.selectedWorkspaceId]: '',
      };
      return UserAPICore.updateUser(user.userId, updates, USER_PATH.workspaces.selectedWorkspaceId);
    }
    return 'rejected';
  };

  static createInitialPrivateWorkspace = async (user: User): Promise<WorkspaceData> => {
    console.log('createInitialPrivateWorkspace');
    Logger.Workspaces.logCreateInitialPrivateWorkspace(user.userId);
    const userName = user.data.firstName || user.data.name;
    const newWorkspaceName = `${userName}'s Shepherd`;
    const newWorkspace = WorkspacesUtils.createNewWorkspace(newWorkspaceName, user.data.email);
    const workspaceId = await WorkspaceAPI.createWorkspace(newWorkspace, user.userId);
    const createdWorkspace = new WorkspaceData(workspaceId, newWorkspace);
    return createdWorkspace;
  };
}
export default UserWorkspacesAPI;
