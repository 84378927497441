import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import WorkspaceMember from '../../../../database/Workspaces/WorkspaceMember';
import WorkspaceMemberRowMol from './WorkspaceMemberRowMol';
import UserIcon from '../../../icons/UserIcon';
import Font from '../../../typography/Font';
import InviteByEmailInputCtrl from '../../input/InviteByEmailInput/InviteByEmailInputCtrl';
import { WorkspacePermissionExpanded } from '../../../types/types';
import WorkspaceData from '../../../../database/Workspaces/WorkspaceData';
import { UserDataContext } from '../../../../App';
import { toastInfo } from '../../../../utils/notifications';

const Container = styled.div``;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  margin-bottom: 24px;
`;

const AddMemberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 24px;
  padding: 2px 0 2px 30px;
  border-radius: 8px;

  :hover {
    background: aliceblue;
    cursor: pointer;
  }

  > *:first-child {
    margin-right: 8px;
  }
  span {
    font-size: 14px;
  }
  
`;

interface WorkspaceMembersOrgProps {
  members: WorkspaceMember[]
  workspace: WorkspaceData,
  // eslint-disable-next-line no-unused-vars
  setMemberPermission: (email: string, newPermission: WorkspacePermissionExpanded) => void,
  // eslint-disable-next-line no-unused-vars
  setIsPermissionsOpen: (isOpen: boolean) => void
}

const WorkspaceMembersOrg = ({
  members, workspace, setMemberPermission,
  setIsPermissionsOpen,
}: WorkspaceMembersOrgProps) => {
  const userData = useContext(UserDataContext);
  const [isAddingMember, setIsAddingMember] = useState(false);

  const onClickAddMember = () => {
    if (userData.getMyWorkspacePermission() !== 'admin') {
      toastInfo('Only admins can invite members');
      return;
    }

    setIsAddingMember(true);
  };

  const handleReset = () => {
    setIsAddingMember(false);
  };
  return (
    <Container>
      <MembersContainer>
        {members.map((member) => (
          <WorkspaceMemberRowMol
            key={member.userId + member.email}
            member={member}
            workspace={workspace}
            setMemberPermission={setMemberPermission}
            setIsPermissionsOpen={setIsPermissionsOpen}
          />
        ))}
      </MembersContainer>
      {
          !isAddingMember ? (
            <AddMemberContainer onClick={onClickAddMember}>
              <UserIcon />
              <Font font="small" color="blue6">Add member</Font>
            </AddMemberContainer>
          ) : (
            <InviteByEmailInputCtrl onReset={handleReset} />
          )
        }
    </Container>
  );
};

export default WorkspaceMembersOrg;
