import firebase from 'firebase';
import SmartReturnData from '../../shared/classes/SmartReturnData';
import {
  ResolvedState, WorkspaceDb, WorkspacePermissionExpanded,
} from '../../shared/types/types';
import SentryAPI from '../../utils/analytics/SentryAPI';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';
import { COLLECTIONS, WORKSPACE_PATH } from '../FirebaseConstants';
import WorkspaceData from './WorkspaceData';
import WorkspacesUtils from './WorkspacesUtils';
import InviteClass from '../../shared/classes/Invite/InviteClass';

class WorkspaceCoreAPI {
  protected static async coreGetWorkspaceByWorkspaceId(
    workspaceId: string,
  ): Promise<SmartReturnData<WorkspaceData>> {
    if (!workspaceId) {
      return SmartReturnData.rejected('WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId: workspaceId is null');
    }
    const result = await firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId: doc doesn't exist", workspaceId);
          return SmartReturnData.rejected('WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId: doc does not exist');
        }

        const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(doc.data());
        const workspaceData = new WorkspaceData(doc.id, workspaceDb);
        return new SmartReturnData(workspaceData, RESOLVED);
      })
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId', error, workspaceId);
        return SmartReturnData.rejected('WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId error');
      });
    return result;
  }

  protected static coreGetWorkspaces = async (email: string) => {
    const result = await firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .where('permissions.members', 'array-contains', email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          return [];
        }

        const workspaces: WorkspaceData[] = querySnapshot.docs.map((doc) => {
          const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(doc.data());
          return new WorkspaceData(doc.id, workspaceDb);
        });
        return workspaces;
      })
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreGetWorkspaces', error, email);
        return [] as WorkspaceData[];
      });
    console.log('workspaces in coreGetWorkspaces', result);
    return result;
  }

  protected static coreListenToMyWorkspaces = (
    // eslint-disable-next-line no-unused-vars
    email: string, callback: (workspaces: WorkspaceData[]) => void,
    // eslint-disable-next-line no-unused-vars
    setIsLoading: (isLoading: boolean) => void,
  ) => {
    setIsLoading(true);
    const unsubscribe = firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .where('permissions.members', 'array-contains', email)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          callback([]);
          return;
        }

        const workspaces: WorkspaceData[] = querySnapshot.docs.map((doc) => {
          const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(doc.data());
          return new WorkspaceData(doc.id, workspaceDb);
        });
        callback(workspaces);
        setIsLoading(false);
      }, (error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.listenToMyWorkspaces', error, email);
        callback([] as WorkspaceData[]);
        setIsLoading(false);
      });

    return unsubscribe;
  }

  protected static coreListenToWorkspace = (
    workspaceId: string,
    // eslint-disable-next-line no-unused-vars
    callback: (workspace: WorkspaceData) => void, setIsLoading: (isLoading: boolean) => void,
  ) => {
    setIsLoading(true);
    const unsubscribe = firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          // callback(new WorkspaceData(querySnapshot.id, querySnapshot.data() as WorkspaceDb));
          return;
        }

        const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(querySnapshot.data());
        const workspace = new WorkspaceData(querySnapshot.id, workspaceDb);
        callback(workspace);
        setIsLoading(false);
      }, (error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreListenToWorkspace', error, workspaceId);
        // callback();
        setIsLoading(false);
      });

    return unsubscribe;
  }

  /**
   * @returns workspaceId - the id of the created workspace
   */
  protected static coreCreateWorkspace = async (workspace: WorkspaceDb) => {
    const result = await firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .add(workspace)
      .then((docRef) => docRef.id as string)
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreCreateWorkspace', error, workspace);
        return '';
      });

    console.log('result in coreCreateWorkspace', result);
    return result;
  }

  protected static coreDeleteWorkspace = async (workspaceId: string) => {
    const result = firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .delete()
      .then(() => 'resolved' as ResolvedState)
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreDeleteWorkspace', error, workspaceId);
        return REJECTED;
      });

    return result;
  }

  protected static coreInviteMemberToWorkspace = async (workspaceId: string, email: string) => {
    const updates = {
      [WORKSPACE_PATH.permissions.invites]: firestore.FieldValue.arrayUnion(email),
    };
    const result = firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .update(updates)
      .then(() => SmartReturnData.resolved())
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreInviteMemberToWorkspace', error, workspaceId);
        return SmartReturnData.rejected(error);
      });

    return result;
  }

  // TODO: If on premium, need to upgrade to add seats to subscription
  protected static coreChangePermissionsOfMember = (
    workspaceId: string, email: string, newPermission: WorkspacePermissionExpanded,
  ) => {
    console.log('coreChangePermissionsOfMember', { workspaceId, email, newPermission });
    let updates = {};
    if (newPermission === null) {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.invites]: firestore.FieldValue.arrayRemove(email),
      };
    }
    if (newPermission === 'admin') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: firestore.FieldValue.arrayUnion(email),
        [WORKSPACE_PATH.permissions.managers]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: firestore.FieldValue.arrayUnion(email),
      };
    }
    if (newPermission === 'manager') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: firestore.FieldValue.arrayUnion(email),
        [WORKSPACE_PATH.permissions.editors]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: firestore.FieldValue.arrayUnion(email),
      };
    }
    if (newPermission === 'editor') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: firestore.FieldValue.arrayUnion(email),
        [WORKSPACE_PATH.permissions.members]: firestore.FieldValue.arrayUnion(email),
      };
    }
    if (newPermission === 'member') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: firestore.FieldValue.arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: firestore.FieldValue.arrayUnion(email),
      };
    }

    const result = firestore()
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .update(updates)
      .then(() => SmartReturnData.resolved())
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreChangePermissionsOfMember', error, workspaceId);
        return SmartReturnData.rejected(error);
      });

    return result;
  }

  /**
   * We don't need to send email since inside the CF we fetch the email from the
   * authenticated user
   */
  protected static coreGetInvitesByEmail = async (): Promise<InviteClass[]> => firebase.app()
    .functions('europe-west1')
    .httpsCallable('getInvites')()
    // eslint-disable-next-line arrow-body-style
    .then((result) => {
      if (!result.data) return [];
      // console.log('result in coreGetInvitesByEmail', result);
      return result.data.map((invite: any) => new InviteClass(invite));
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('coreGetInvitesByEmail', error);
      throw new Error(error);
    })

  protected static coreAcceptInvite = async (workspaceId: string) => firebase.app()
    .functions('europe-west1')
    .httpsCallable('acceptWorkspaceInvite')({ workspaceId })
    .then((result) => {
      console.log('result in coreAcceptInvite', result);
      return result;
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('coreAcceptInvite', error);
      throw new Error(error);
    })

  protected static coreDeclineInvite = async (workspaceId: string) => firebase.app().functions('europe-west1')
    .httpsCallable('declineWorkspaceInvite')({ workspaceId })
    .then((result) => {
      console.log('result in coreDeclineInvite', result);
      return result;
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('coreDeclineInvite', error);
      throw new Error(error);
    })
}

export default WorkspaceCoreAPI;
