/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import TurndownService from 'turndown';
import _, { filter, remove } from 'lodash';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import moment from 'moment';
import mdToSlackBlocks from '../../utils/slack/SlackFormatting';
import { AuthContext, UserDataContext } from '../../App';
import { gray4, surface } from '../../shared/colors/COLORS';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { SCOPES } from '../../utils/constants';
import { firestore, functions } from '../../utils/firebase';
import {
  getThisWeeksUpcomingMeetings, gapiGetRecurringEventMeetings,
  getMeetingsInPeriod,
} from '../../utils/google/GoogleCalendarAPI';
import { toastDanger, toastInfo } from '../../utils/notifications';
import ShareNotesModal from '../meeting/modal/ShareModal';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../database/utils/templateMeetingData';
import {
  AuthState, BasicUser, GapiMeetingData, MeetingData, SimpleUserData,
} from '../../shared/types/types';
import TrelloSettingModalWrapper from '../meeting/modal/TrelloModalComponents/TrelloSettingModalWrapper';
import MyNotesSharePromptModal from '../meeting/modal/ShareModal/Molecule/MyNotesSharePromptModal';
import ArrowRight from '../../shared/icons/arrowRight';
import ArrowRight2 from '../../shared/icons/ArrowRight2';
import Notification from '../../shared/classes/Notification/Notification';
import useNotificationStore from '../../zustand/useNotificationStore';
import NotificationAPI from '../../shared/classes/Notification/NotificationAPI';
import Dropdown from '../../shared/components/dropdown';
import Modal from '../../shared/components/Modal';
import { header200 } from '../../shared/typography';
import { DropdownItem } from '../../shared/components/dropdown/utils';
import DropDownWithInputControl from '../../shared/components/dropdown/DropDownWithInputControl';
import { gapiSearchEvents } from '../../utils/google/GoogleCalendarCore';
import { defaultAuthContext } from '../../utils/user/UserDataUtils';
import initializeGoogleAPI from '../../utils/google/GoogleCalendarSetup';
import CalendarIcon from '../../shared/icons/CalendarIcon';
import Font from '../../shared/typography/Font';
import mapDatabaseMeetingDataToMeetingData from '../../database/utils/mapMeetingData';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import searchMeetings from '../../utils/algolia';
import AlgoliaAPICore from '../../database/Algolia/AlgoliaAPICore';
import GAPI from '../../external/GoogleAPI/GAPI';

declare let gapi: any;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const PaddingContainer = styled.div`
  padding: 24px;
`;

const Label = styled.p`
  ${header200};
  color: ${surface};
  margin: 12px 0 12px 0;
`;

const AsishTestingPage = () => {
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const data2 = useNotificationStore();
  const { updateStore, notifications } = data2;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>([]);

  const [GAPILoading, setGAPILoading] = useState<boolean>(false);
  const [isGAPIError, setIsGAPIError] = useState<boolean>(false);
  const [GAPIPlaceHolder, setGAPIPlaceHolder] = useState<string>('search your meeting');
  const [selectedGAPISearchQuery, setSelectedGAPISearchQuery] = useState<string>('');
  const [gapiDDItems, setgapiDDItems] = useState<DropdownItem[]>([] as DropdownItem[]);

  const [algoliaLoading, setalgoliaLoading] = useState<boolean>(false);
  const [isalgoliaError, setIsalgoliaError] = useState<boolean>(false);
  const [algoliaPlaceHolder, setalgoliaPlaceHolder] = useState<string>('search your meeting');
  const [selectedalgoliaSearchQuery, setSelectedalgoliaSearchQuery] = useState<string>('');
  const [algoliaDDItems, setalgoliaDDItems] = useState<DropdownItem[]>([] as DropdownItem[]);

  const searchClient = algoliasearch('9SYOBYSAN8', '745ded45ddbbe63181ba45238d99f2ce');
  const searchIndex = searchClient.initIndex('meetings_algolia');

  // useEffect(() => {
  //   console.log('User Data');
  //   console.log(userData);
  //   if (authData.userId) {
  //     NotificationAPI.listenToUserNotifications(authData.userId, updateStore);
  //   }
  //   return () => { };
  // }, [authData, userData]);

  // useEffect(() => {
  //   console.log({ inEffect: notifications });
  // }, [notifications]);
  /*
  const handleClick1 = () => {
    toastInfo('Clicked', 'Button 1 clicked');

    gapi.auth2.getAuthInstance().currentUser.get().grantOfflineAccess({
      access_type: 'offline',
      prompt: 'consent',
      scopes: SCOPES,
    }).then((data:any) => {
      console.log(data);
      functions().httpsCallable('getGoogleTokens2')({
        userId: userData.userId,
        exchangeCode: data.code,
      }).then((response) => {
        console.log('response', response);
      });
    });
  };
  const handleClick2 = () => {
    toastInfo('Clicked', 'Button 2 clicked');
    firestore()
      .collection('tasks')
      .add({ asish: 'hello' }).then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClick3 = () => {
    toastInfo('Clicked', 'Button 3 clicked');
    functions().httpsCallable('checkNotificationPermission')({
      userId: userData.userId,
    }).then((data) => {
      console.log(data);
    });
  };
  const handleClick4 = () => {
    const turndownService = new TurndownService();
    const html = `
<div><strong>Asish</strong> mentioned you in <strong>PH Launch meet</strong></div>
<div><a href="https://www.w3schools.com">Click here</a>to view and edit the notes</div>
`;
    const slackBlocks = mdToSlackBlocks(turndownService.turndown(html));
    console.log(JSON.stringify(slackBlocks));
  };

  const handleClick5 = () => {
    toastDanger(
      'Integration failed', 'You selected a database-page,
      which we unfortunately cannot integrate with. Please select a normal
       page in Notion. A database page is a "special" format page,
       i.e. table page / list page.');
    gapiGetRecurringEventMeetings('vashish888@gmail.com',
    '2p657pv4uahd1fa32itlh8mn4m').then(async (data: GapiMeetingData[]) => {
      console.log(data);
      const user: SimpleUserData = await FirestoreUserAPI
      // .dbGetSimpleUserDataByUserId(userData.userId, 'viewer');
      const newMeeting = data.map((googleMeeting) => mapGoogleMeetingToDatabaseMeetingData(
        googleMeeting,
        'vashish888@gmail.com',
        user,
      ));
    });
  };

  const handleClick6 = async () => {
    const notificationSource = Notification.generateNotificationSource('task', 'someId');
    console.log(notificationSource);
    const recipient: BasicUser = {
      userId: 'NhhJE3Hp31U3d1edj9K1JUrEPrg1',
      name: 'Asish',
      email: 'asish@meetshepherd.com',
      photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GilLa6eQmJgqHMvBvutZLF-Li2G4TO_6lmbxFMhTg=s96-c',
    };
    const reporter: BasicUser = {
      userId: 'hmY5emSFdJXgD9P7BL9xla1lXMr2',
      name: 'Jamie',
      email: 'jamie@meetshepherd.com',
      photoUrl: 'https://lh5.googleusercontent.com/-1xmcpichtec/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclA1y2_6gdK_SaMzyB3pJaNwS3hww/s96-c/photo.jpg',
    };
    const notification = Notification.generateNotification(recipient, reporter, notificationSource);
    await notification.addNotificationToDb();
    // await notification.updateIsRead(true);
    // await notification.updateIsDismissed(true);
  };
  const close = () => {
    setIsOpen(!isOpen);
  };

  const handleClick7 = () => {
    close();
  };

  const tempData: DropdownItem[] = [
    {
      id: '1',
      name: 'asish',
      icon: null,
    },
    {
      id: '2',
      name: 'hello',
      icon: null,

    },
    {
      id: '3',
      name: 'hey',
      icon: null,

    },
    {
      id: '4',
      name: 'haha',
      icon: null,

    },
    {
      id: '5',
      name: 'hey-5',
      icon: null,

    },
    {
      id: '6',
      name: 'hey-6',
      icon: null,

    },
    {
      id: '7',
      name: 'hey-7',
      icon: null,
    },
  ];

  const tempData2: DropdownItem[] = [
    {
      id: '1',
      name: 'asish',
      icon: null,
    },
    {
      id: '2',
      name: 'hello',
      icon: null,

    },
    {
      id: '3',
      name: 'hey',
      icon: null,

    },
    {
      id: '4',
      name: 'haha',
      icon: null,

    },
    {
      id: '5',
      name: 'hey-5',
      icon: null,

    },
    {
      id: '6',
      name: 'hey-6',
      icon: null,

    },
    {
      id: '7',
      name: 'hey-7',
      icon: null,
    },
  ];

  const onItemClick = (item:any) => {
    setSelectedItem([...selectedItem, item]);
  };

  const onSingleItemClick = (item:any) => {
    setSelectedItemText(item.name);
  };

  const handleClick8 = () => {
    // eslint-disable-next-line no-undef
    console.log(filter(tempData, { id: 1 }));
  };
  */

  const onGAPISearchResultSelect = (item: DropdownItem) => {
    console.log(item);
    setGAPIPlaceHolder(item.name);
  };

  const onAlgoliaSearchResultSelect = (item: DropdownItem) => {
    console.log(item);
    setalgoliaPlaceHolder(item.name);
  };

  const fetchGAPIResponse = async () => {
    setGAPILoading(true);
    const data = await gapiSearchEvents(selectedGAPISearchQuery, userData.data.email);
    console.log({ data });
    const dropdownItem = data.map((item) => {
      const meetingName = `${item.summary} | ${moment(item?.start?.dateTime ?? '').format('LLL')}`;
      const ddItem: DropdownItem = {
        id: item.id,
        name: meetingName ? meetingName.slice(0, 50) : '',
        icon: <CalendarIcon />,
      };
      return ddItem;
    });
    setGAPILoading(false);
    setgapiDDItems(dropdownItem);
  };

  const fetchAlgoliaResponse = async () => {
    const results = await searchMeetings(
      selectedalgoliaSearchQuery, userData.data.email, userData.userId,
    );
    ConsoleImproved.log('Algolia meetings', results);

    const dropdownItem = results.map((meeting: MeetingData) => {
      const ddItem: DropdownItem = {
        id: meeting.googleData.ids.eventId,
        name: `${meeting.data.title.slice(0, 50)} | ${moment(meeting.date.start.date).format('LLL')}`,
        icon: <CalendarIcon />,
      };
      return ddItem;
    });
    setalgoliaDDItems(dropdownItem);
  };

  const searchMeetingsByAlgolia = async () => {
    const myCalendarIds = await GAPI.getMyCalendarIds();
    ConsoleImproved.log('My calendar ids', myCalendarIds);

    const result = await searchIndex.search(selectedalgoliaSearchQuery, {
      filters: `data.attendees.email:${userData.data.email} OR ${AlgoliaAPICore.generateSearchFilter(myCalendarIds)}`,
    });

    ConsoleImproved.log('Results', result.hits);
    const dropdownItem = result.hits.map((meeting: any) => {
      const ddItem: DropdownItem = {
        id: meeting.googleData.ids.eventId,
        name: `${meeting.data.title.slice(0, 50)} | ${moment(meeting.date.start.date).format('LLL')}`,
        icon: <CalendarIcon />,
      };
      return ddItem;
    });
    setalgoliaDDItems(dropdownItem);
  };

  useEffect(() => {
    if (authData.userId) {
      console.log(selectedGAPISearchQuery);
      fetchGAPIResponse();
    }
  }, [authData, userData, selectedGAPISearchQuery]);

  useEffect(() => {
    if (authData.userId) {
      console.log(selectedalgoliaSearchQuery);
      // fetchAlgoliaResponse();
      searchMeetingsByAlgolia();
    }
  }, [authData, userData, selectedalgoliaSearchQuery]);

  return (
    <Container>
      <TitleAtom>Asish World </TitleAtom>
      <Font font="header400" color="gray1">GAPI</Font>
      <DropDownWithInputControl
        isLoading={GAPILoading}
        isError={isGAPIError}
        dropdownItems={gapiDDItems}
        isMultiSelect={false}
        placeholderText={GAPIPlaceHolder}
        onOptionClick={onGAPISearchResultSelect}
        inputText={selectedGAPISearchQuery}
        setInputText={setSelectedGAPISearchQuery}
      />
      <Font font="header400">Algolia</Font>
      <DropDownWithInputControl
        isLoading={algoliaLoading}
        isError={isalgoliaError}
        dropdownItems={algoliaDDItems}
        isMultiSelect={false}
        placeholderText={algoliaPlaceHolder}
        onOptionClick={onAlgoliaSearchResultSelect}
        inputText={selectedalgoliaSearchQuery}
        setInputText={setSelectedalgoliaSearchQuery}
      />
      {/* <ButtonSmall text="Click 1" onClick={handleClick1} />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      <ButtonSmall text="Click 3" onClick={handleClick3} />
      <ButtonSmall text="Click 4" onClick={handleClick4} />
      <ButtonSmall text="Click 5" onClick={handleClick5} />
      <ButtonSmall text="Click 6" onClick={handleClick6} />
      <ButtonSmall text="Click 7" onClick={handleClick7} />
      <ButtonSmall text="Click 8" onClick={handleClick8} />
      <Modal isOpen={isOpen} setModalClosed={close}>
        <PaddingContainer>
          <Label>Single Select Dropdown</Label>
          <Dropdown
            selectedItems={selectedItem}
            setSelectedItems={setSelectedItem}
            dropdownItems={tempData}
            isMultiSelect={false}
            placeholderText={selectedItemText}
            onOptionClick={onSingleItemClick}
          />
          <Label>Multi Select Dropdown</Label>
          <Dropdown
            selectedItems={selectedItem}
            setSelectedItems={setSelectedItem}
            dropdownItems={tempData2}
            isMultiSelect
            placeholderText="Choose Options"
            onOptionClick={onItemClick}
          />
        </PaddingContainer>
      </Modal> */}
      {/* <MyNotesSharePromptModal isOpen={isOpen} handleCloseClick={close} /> */}
    </Container>
  );
};

export default AsishTestingPage;
