import {
  uniqueNamesGenerator, Config, adjectives, animals,
} from 'unique-names-generator';

const customConfig: Config = {
  dictionaries: [adjectives, animals],
  separator: ' ',
  length: 2,
  style: 'capital',
};

class RandomNamesGenerator {
  static generate(): string {
    return uniqueNamesGenerator(customConfig);
  }
}

export default RandomNamesGenerator;

// const randomName: string = uniqueNamesGenerator({
//   dictionaries: [adjectives, colors, animals]
// }); // big_red_donkey

// const shortName: string = uniqueNamesGenerator(customConfig); // big-donkey
