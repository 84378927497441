/* eslint-disable no-unused-vars */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';

import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom-v5-compat';
import { captureMessage } from '@sentry/react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  gray1, gray4, darkBlue1, red6, COLORS,
} from '../../colors/COLORS';
import {
  messageText, uiTextMedium,
} from '../../typography';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import { signOutOfGoogleAndFirebase } from '../../../utils/google/GoogleCalendarSetup';
import Settings from './settings/Settings';
import {
  logEventButtonClickOpenIntercom,
  logEventButtonClickQuickSettings,
  logIntegrationsEvent,
  logQuickSettingsClick,
} from '../../../utils/analytics/eventLogger';
import ProfilePicture from '../profile-picture';
import FeedbackModal from '../Modal/FeedbackModal';
import IntegrationsModal from './integrations/Modal';
import MessageIcon from '../../icons/MessageIcon';
import SettingsIcon18x18 from '../../icons/SettingsIcon18x18';
import StarIcon from '../../icons/StarIcon';
import ThumbsUp18x18 from '../../icons/ThumbsUp18x18';
import Chat18x18 from '../../icons/Chat18x18';
import HelpCenter from '../../icons/HelpCenter';
import KeyboardIcon from '../../icons/KeyboardIcon';
import ShortcutsModal from '../shortcuts';
import FireworkConfetti from '../../confetti/FireworkConfetti';
import { DetectedOperatingSystemContext } from '../../../utils/context/OperatingSystemContext';
import CameraIcon from '../../icons/CameraIcon';
import ROUTES from '../../../routes/ROUTES_CONSTANTS';
import { AuthContext, UserDataContext } from '../../../App';
import ProfileIcon from '../profile-icon';
import { calculateIfSlackIsEnabled } from '../../../utils/slack/slackUtils';
import AboutModal from './aboutModal/AboutModal';
import InviteMembersModalOrganism from '../invite-members-modal/InviteMembersModalOrganism';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import useGetQueryParam from '../../../utils/hook/useGetParams';
import PathGenerator from '../../../utils/PathGenerator';
import {
  CLOSE_EVENT, OPEN_EVENT, SETTINGS_MODAL_LOCATION,
} from '../../../utils/analytics/enums';
import InfoCircle from '../../icons/infoCircle';
import { fadeIn } from '../../../pages/onboarding/welcome/utils/animations';
import Font from '../../typography/Font';
import ConsoleImproved from '../../classes/ConsoleImproved';
import { Page } from '../../types/types';
import DollarIcon from './DollarIcon';
import { toastDanger } from '../../../utils/notifications';
import StripeAPI from '../../../external/Stripe/StripeAPI';
import { RESOLVED } from '../../../utils/enums';
import { QueryParameters } from '../../../utils/meetings/QueryUtils';
import KnownUsersUtils from '../../../utils/user/KnownUsers.ts/KnownUsersUtils';
import { StripeConstants } from '../../../external/Stripe/StripeConstants';
import StripeSubscriptionUtils from '../../../external/Stripe/StripeSubscriptionUtils';
import SlackBoltAPI from '../../../external/SlackBolt/SlackBoltAPI';
import IntegrationsIcon from '../../icons/IntegrationsIcon';
import QuickSettingsMenuOrg from './QuickSettingsMenuOrg';

const Container = styled.div`
  position: relative;
  margin: 0 12px;
  display: ${(props: ProfileSetting) => (props.photoUrl === '' ? 'auto' : 'flex')};
  align-items: ${(props: ProfileSetting) => (props.photoUrl === '' ? 'auto' : 'center')};
  z-index: 19;
  margin-left: 0px;
`;

const Trigger = styled.div`
  cursor: pointer;
  border-radius: 4px;
`;

interface ProfileSetting {
  photoUrl: string,
}

type ToggleIntercomSource = 'chat_with_us' | 'help_us';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
}

// eslint-disable-next-line no-unused-vars
const QuickSettings = ({ setPage }: Props) => {
  const stripe = useStripe();
  const { openInviteMemberModal, isInviteMemberModalOpen, closeInviteMemberModal } = useUI();
  const { detectedOperatingSystem } = useContext(DetectedOperatingSystemContext);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);
  const [isChatBubbleOpen, setIsChatBubbleOpen] = useState(false);
  const { show, hide, trackEvent } = useIntercom();
  const navigate = useNavigate();
  const authData = useContext(AuthContext);
  const {
    firstName, lastName, email, photoUrl, userId,
  } = authData;
  const userData = useContext(UserDataContext);

  const isSettingsOpenURLParam = useGetQueryParam(PathGenerator.quickSettingParam) === 'true';

  useEffect(() => {
    if (isSettingsOpenURLParam) openSettingsModal();
  }, [isSettingsOpenURLParam]);

  useEffect(() => {
    if (userData.resolvedState !== RESOLVED) return;
    const openModal = useGetQueryParam(QueryParameters.OPEN_INVITE_USERS_MODAL);
    if (openModal !== 'true') return;
    openMembersModal();
    // eslint-disable-next-line no-restricted-globals
  }, [location, userData.resolvedState]);

  const toggleIntercom = (source: ToggleIntercomSource) => () => {
    if (isChatBubbleOpen) {
      hide();
      setIsChatBubbleOpen(false);
      logEventButtonClickOpenIntercom(authData.userId, trackEvent);
      logQuickSettingsClick(authData.userId, CLOSE_EVENT, source, trackEvent);
    } else {
      show();
      setIsChatBubbleOpen(true);
      logQuickSettingsClick(authData.userId, OPEN_EVENT, source, trackEvent);
    }
  };
  const [integrationsModalOpen, setIntegrationsModalOpen] = useState<boolean>(false);
  const [activateConfetti, setActivateConffetti] = useState<boolean>(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const [isQuickSettingsOpen, setIsQuickSettingsOpen] = useDetectOutsideClick(dropdownRef, false);

  const initial = firstName[0];
  const fullName = `${firstName} ${lastName}`;

  useHotkeys('ctrl+/', () => {
    if (detectedOperatingSystem === 'Windows' || detectedOperatingSystem === 'Linux') {
      setIsQuickSettingsOpen(false);
      setIsShortcutsModalOpen((prev) => !prev);
    }
  }, [isShortcutsModalOpen, detectedOperatingSystem]);

  useHotkeys('Command+/', () => {
    if (detectedOperatingSystem === 'Mac') {
      setIsQuickSettingsOpen(false);
      setIsShortcutsModalOpen((prev) => !prev);
    }
  }, [isShortcutsModalOpen, detectedOperatingSystem]);

  const onToggleQuickSettingsMenu = () => {
    setIsQuickSettingsOpen(!isQuickSettingsOpen);
    logEventButtonClickQuickSettings(authData.userId, trackEvent);
    closeAllOtherModals();
  };

  const closeAllOtherModals = () => {
    setIsShortcutsModalOpen(false);
    setSettingsModalOpen(false);
    setIntegrationsModalOpen(false);
    setIsAboutModalOpen(false);
    setIsFeedbackModalOpen(false);
    closeInviteMemberModal();
  };

  const handleShortcutsModalOpen = () => {
    setIsShortcutsModalOpen(true);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'shortcuts', trackEvent);
  };

  const handleFeedbackModalOpen = () => {
    setIsFeedbackModalOpen(true);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'feedback', trackEvent);
  };

  const handleToggleIntegrationsModal = () => {
    setIntegrationsModalOpen(!integrationsModalOpen);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'integrations', trackEvent);
    logIntegrationsEvent(userId, OPEN_EVENT, '', SETTINGS_MODAL_LOCATION);
  };

  const openMembersModal = () => {
    openInviteMemberModal();
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'invite_members', trackEvent);
  };

  const openSettingsModal = () => {
    setIsQuickSettingsOpen(false);
    setSettingsModalOpen(true);
    logQuickSettingsClick(userId, OPEN_EVENT, 'settings', trackEvent);
  };

  useEffect(() => {
    if (activateConfetti) {
      setTimeout(() => {
        setActivateConffetti(false);
      }, 4000);
    }
  }, [activateConfetti]);

  const handleTutorialVideoClick = () => {
    navigate(ROUTES.tutorialVideoPath);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'tutorial', trackEvent);
  };

  const toggleAboutModal = () => {
    setIsAboutModalOpen((prev) => !prev);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'about', trackEvent);
  };

  const handleClickBilling = async () => {
    ConsoleImproved.log('Clicked billing');
    // We want to redirect them directly to Stripe billing page
    // setPage(ALL_PAGES.USER_CENTER_BILLING);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'billing', trackEvent);
    try {
      const session = await StripeAPI.createPortalSession(userData, StripeConstants.isTest);
      window.open(session.url, '_blank');
    } catch (error: any) {
      captureMessage('Error creating Stripe portal session', {
        extra: {
          error,
          userBillingData: userData.billing,
        },
      });
      console.log(error);
      toastDanger('Error creating Stripe portal session', error?.message ?? 'Unknown error');
      ConsoleImproved.log('Error creating Stripe portal session', error);
    }
  };

  const handleClickSubscribe = async () => {
    try {
      const origin = userData.isOnFreePlan()
        ? StripeConstants.CHECKOUT_ORIGINS.AtEndedTrial
        : StripeConstants.CHECKOUT_ORIGINS.DuringTrial;
      await StripeAPI.createAndRedirectToCheckoutSession(
        userData, origin,
        userData.workspace.numberOfMembers, trackEvent, stripe,
      );
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const isKnownUserId = KnownUsersUtils.isKnownUser(authData.userId);
  const isSlackEnabled = calculateIfSlackIsEnabled(userData);

  const isOnFreePlan = userData.isOnFreePlan();

  return (
    <Container photoUrl={photoUrl}>
      <Trigger onClick={onToggleQuickSettingsMenu}>
        <ProfileIcon user={userData.publicUserData} />
      </Trigger>
      <QuickSettingsMenuOrg
        closeFeedbackModal={closeFeedbackModal}
        isFeedbackModalOpen={isFeedbackModalOpen}
        isQuickSettingsOpen={isQuickSettingsOpen}
        setIsQuickSettingsModalOpen={setIsQuickSettingsOpen}
        dropdownRef={dropdownRef}
        handleClickBilling={handleClickBilling}
        handleClickUpgradeToPremium={handleClickSubscribe}
        handleFeedbackModalOpen={handleFeedbackModalOpen}
        handleShortcutsModalOpen={handleShortcutsModalOpen}
        handleToggleIntegrationsModal={handleToggleIntegrationsModal}
        handleTutorialVideoClick={handleTutorialVideoClick}
        isKnownUserId={isKnownUserId}
        openMembersModal={openMembersModal}
        openSettingsModal={openSettingsModal}
        toggleAboutModal={toggleAboutModal}
        toggleIntercom={toggleIntercom}
        key={fullName}
      />
      <IntegrationsModal
        isOpen={integrationsModalOpen}
        setClosed={handleToggleIntegrationsModal}
        setActivateConffetti={setActivateConffetti}
        activateConfetti={activateConfetti}
      />
      <ShortcutsModal isOpen={isShortcutsModalOpen} setIsOpen={setIsShortcutsModalOpen} />
      <InviteMembersModalOrganism
        isOpen={isInviteMemberModalOpen}
        setClosed={closeInviteMemberModal}
      />
      <Settings
        isOpen={isSettingsModalOpen}
        setIsOpen={setSettingsModalOpen}
        isSlackEnabled={isSlackEnabled}
      />
      <AboutModal isOpen={isAboutModalOpen} setModalClosed={toggleAboutModal} />
      <FireworkConfetti shouldTrigger={activateConfetti} />
    </Container>
  );
};

export default QuickSettings;
